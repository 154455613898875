import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AttributesVpRate } from '@core/enums/attributes-vp-rate.enum';
import { AppService } from '@core/services/app.service';
import {
  ValidateFwmbsInput,
  ValidationService,
} from '@core/services/validation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { ControlUnits } from '@shared/models/ControlUnits';

@Component({
  selector: 'app-fcsc-fw-control-units-modal',
  templateUrl: './fcsc-fw-control-units-modal.component.html',
  styleUrls: ['./fcsc-fw-control-units-modal.component.scss'],
})
export class FcscFwControlUnitsModalComponent implements OnInit {
  @Input() title!: string;
  @Input() subText!: string;
  @Input() data!: ControlUnits;
  @Input() fieldText!: string;
  @Input() buttonText!: string;
  @Input() index!: number;
  @Input() dataList!: ControlUnits[];
  attributesVpRateItems!: NgOption[];

  form!: UntypedFormGroup;
  activateEditFlag!: boolean;
  attributes!: any;
  attributeStaticKey = expertToolConstants.keys.vpRateModulesStaticKey;
  okValue = '';
  initialValue = '';
  buttonEnabled = false;
  chips: any = [];
  allChipsValid: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private translocoService: TranslocoService,
    private validationService: ValidationService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.onChipsChanged(true);
    this.activateEdit();
    this.attributesVpRateItems =
      this.appService.translatedEnum(AttributesVpRate);
    this.attributes = [1];
    if (this.attributesVpRateItems[4]) {
      this.attributesVpRateItems[4].disabled = true;
    }

    this.initialValue =
      this.attributeStaticKey +
      (this.data && this.data.attributes
        ? this.data.attributes
        : expertToolConstants.values.byPriority);
  }

  onUserKeyPress(event: KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    // a property which is set with the initial value
    if (!value.startsWith(this.attributeStaticKey)) {
      event.preventDefault();
    }
  }

  onUserKeyDown(event: any) {
    const value = (event.target as HTMLInputElement).value;
    if (
      !value.startsWith(this.attributeStaticKey) &&
      (event.keyCode === 8 || event.keyCode === 46)
    ) {
      event.preventDefault();
    }
  }

  private createForm() {
    const isEdit = this.data !== undefined;
    const editValue = isEdit
      ? this.data.attributes
        ? this.data.attributes
        : expertToolConstants.values.byPriority
      : expertToolConstants.values.byPriority;
    this.form = this.formBuilder.group({
      diogName: new UntypedFormControl(isEdit ? this.data.diogName : ''),
      hwSnr: new UntypedFormControl(isEdit ? this.data.hwSnr : '', [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$'),
      ]),
      swSnrs: new UntypedFormControl(isEdit ? this.data.swSnrs : '', [
        ValidateFwmbsInput,
      ]),
      attributesVpRate: new UntypedFormControl({
        name: this.translocoService.translate(AttributesVpRate.BY_PROIRITY),
      }),
      editedValue: new UntypedFormControl(this.attributeStaticKey + editValue),
    });

    this.form.valueChanges.subscribe((val) => {
      if (val.editedValue.startsWith(this.attributeStaticKey)) {
        this.okValue = val.editedValue;
        setTimeout(() => {
          this.resetValue();
        }, 100);
      } else {
        this.form.get('editedValue')?.setValue(this.okValue, { emit: false });
      }
      this.buttonEnabled = this.allChipsValid && this.form.valid;
    });
    this.resetValue();
    // If the FWonECU is null while clicking on edit, chips should not be loaded in the pop up modal
    this.chips = this.data.swSnrs?.split(' ')[0] != '' ? this.data.swSnrs?.split(' ') : [];
  }

  cancel() {
    this.activeModal.close(null);
  }

  private removeStaticKey(val?: string) {
    return val?.replace(this.attributeStaticKey, '');
  }
  save() {
    const attributes = this.form.get('editedValue')?.value;
    const isValid = this.validationService.validateKeyValuePairs(
      attributes,
      '\n',
      '='
    );
    if (isValid) {
      const record: ControlUnits = {
        diogName: this.form.get('diogName')?.value,
        hwSnr: this.form.get('hwSnr')?.value,
        swSnrs: this.form.get('swSnrs')?.value,
        id: this.data && this.data.id ? this.data.id : this.dataList.length + 1,
        disabled: true,
        selected: true,
        attributes: this.removeStaticKey(attributes),
      };
      const finalRecord = {
        index: this.index,
        checkFlag:
          this.data && this.data.diogName !== this.form.get('diogName')?.value,
        record,
      };
      this.activeModal.close(finalRecord);
    }
  }

  activateEdit() {
    this.activateEditFlag = true;
  }

  resetValue() {
    const editedValue = this.removeStaticKey(
      this.form.get('editedValue')?.value
    );
    if (editedValue) {
      this.activateEdit();
      const translatedValues: any =
        this.appService.translatedEnum(AttributesVpRate);
      const foundValue = translatedValues.find(
        (value: any) => value.name === editedValue.split('\n')[0]
      );
      if (foundValue) {
        this.form.get('attributesVpRate')?.setValue(foundValue);
      } else {
        this.form.get('attributesVpRate')?.setValue({
          name: this.translocoService.translate(AttributesVpRate.USER_SPECIFIC),
        });
      }
    }
  }

  getAtrributes() {
    const attributes = this.form.get('editedValue')?.value;
    return attributes.split('\n').join('<br>');
  }

  changeEditable() {
    const attributesVpRate = this.form.get('attributesVpRate')?.value;
    if (
      attributesVpRate.name ===
      this.translocoService.translate(AttributesVpRate.USER_SPECIFIC)
    ) {
      this.form.get('editedValue')?.setValue('');
    } else {
      const value = this.form.get('editedValue')?.value;
      const vals = value.split('\n');
      vals[0] = this.attributeStaticKey + attributesVpRate.name;
      this.form.get('editedValue')?.setValue(vals.join('\n'));
    }
  }

  isInputInvalid(controlName: string): boolean | undefined {
    return this.validationService.checkInputValidity(this.form, controlName);
  }

  onChipsChanged(allGreen: boolean) {
    this.buttonEnabled = allGreen && this.form.valid;
    this.allChipsValid = allGreen;
  }

  onUpdateChips(chips) {
    this.form.get('swSnrs')?.setValue(chips.join(" "));
    this.chips = chips;
  }
}
