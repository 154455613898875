import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import { MediaType } from '@shared/constants/MediaType';
import { BdpSerieFilter } from '@shared/models/filetype/BdpSerieFilter';
import { BdpStatus } from '@shared/models/filetype/BdpStatus';
import {
  RcddbLookupBr,
} from '@shared/models/filetype/RcddbLookupBr';
import {
  FilteredSeriesBdp,
  SeriesBdp,
  SeriesBdpHistoryFile,
  SeriesBdpResponse,
} from '@shared/models/filetype/SeriesBdp';
import {
  BdpDocumentation,
  SeriesBdpRollback,
} from '@shared/models/filetype/SeriesBdpRollback';
import { SeriesSteuerDatenActivation } from '@shared/models/filetype/SeriesSteuerDatenActivation';
import { SeriesSteuerDatenHistory } from '@shared/models/filetype/SeriesSteuerDatenHistory';
import {
  FilteredSteuerDaten,
  SteuerDaten,
  SteuerDatenResponse,
} from '@shared/models/filetype/SteuerDaten';
import {
  VorabSdpActivation,
  VorabSdpActivationStatus,
} from '@shared/models/filetype/VorabSdpActivation';
import { SteuerdatenPaketFile } from '@shared/models/SteuerdatenPaketFile';
import { Observable } from 'rxjs';

import {
  SeriesSteuerDaten,
  SeriesSteuerDatenQueue,
} from '../../shared/models/filetype/SeriesSteuerDaten';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class SteuerdatenService {
  private baseUrl = '';
  private steuerungBaseUrl = '';
  private calculationArchive = '';
  private smokeTestErrorUrl = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.baseUrl = `${envService.apiUrl}${apiPaths.fcscDatamanager}/api`;
    this.steuerungBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}/api`;
    this.calculationArchive = `${envService.apiUrl}${apiPaths.fcscCalculationArchive}`;
    this.smokeTestErrorUrl = `${envService.apiUrl}${apiPaths.fcscSmokeTestError}`;
  }

  getSteuerDaten(
    dataPool: string,
    parameters?: FilteredSteuerDaten
  ): Observable<SteuerDaten[]> {
    let params = new HttpParams();

    if (parameters) {
      const paramsObj = {};
      Object.keys(parameters).map((key) => {
        paramsObj[key] = parameters[key];
      });
      params = new HttpParams({ fromObject: paramsObj });
    }
    return this.httpClient.get<SteuerDaten[]>(
      `${this.baseUrl}/steuerdaten-paket/${dataPool}`,
      { params }
    );
  }

 getLatestSteuerDaten(
    dataPool: string,
    parameters?: FilteredSteuerDaten
  ): Observable<SteuerDaten[]> {
    let params = new HttpParams();

    if (parameters) {
      const paramsObj = {};
      Object.keys(parameters).map((key) => {
        paramsObj[key] = parameters[key];
      });
      params = new HttpParams({ fromObject: paramsObj });
    }
    return this.httpClient.get<SteuerDaten[]>(
      `${this.baseUrl}/steuerdaten-paket/${dataPool}/latest`,
      { params }
    );
  }

  postVorabSteuerDaten(
    dataPool: string,
    name: string[],
    parameters?: FilteredSteuerDaten
  ): Observable<HttpResponse<SteuerDatenResponse>> {
    let paramsVar = new HttpParams();
    const url = `${this.baseUrl}/steuerdaten-paket/${dataPool}`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    if (parameters) {
      const paramsObj = {};
      Object.keys(parameters).map((key) => {
        paramsObj[key] = parameters[key];
      });
      paramsVar = new HttpParams({ fromObject: paramsObj });
    }
    return this.httpClient.post<SteuerDatenResponse>(url, name, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
      params: paramsVar,
    });
  }

  getPresignedUrlForSmokeTestFile(file: string) {
    const url = `${this.smokeTestErrorUrl}/${file}`;

    return this.httpClient.get(url, {
      responseType: 'json',
    });
  }

  getSeriesSteuerDaten(): Observable<SeriesSteuerDaten[]> {
    return this.httpClient.get<SeriesSteuerDaten[]>(
      `${this.steuerungBaseUrl}/serien-bdp-history/latest/sdp`
    );
  }

  getBdpHistoryFiles(
    seriesBdpName: string
  ): Observable<SeriesBdpHistoryFile[]> {
    return this.httpClient.get<SeriesBdpHistoryFile[]>(
      `${this.steuerungBaseUrl}/serien-bdp-history/${seriesBdpName}/files`
    );
  }

  getSteuerDatenPaketFiles(
    steuerdatenPaketName: string
  ): Observable<SteuerdatenPaketFile[]> {
    return this.httpClient.get<SteuerdatenPaketFile[]>(
      this.baseUrl + '/steuerdaten-paket/' + steuerdatenPaketName + '/files'
    );
  }

  public deleteSteuerDaten(sdp: string): Observable<SteuerDaten[]> {
    const url = `${this.steuerungBaseUrl}/steuerdatenpaket/${sdp}`;
    return this.httpClient.delete<SteuerDaten[]>(url);
  }

  deleteMutipleSteuerDaten(sdps: string[]) {
    const url = `${this.steuerungBaseUrl}/steuerdatenpaket?names=${sdps}`;
    return this.httpClient.delete<SteuerDaten[]>(url);
  }

  getBdpStatus(): Observable<BdpStatus[]> {
    return this.httpClient.get<BdpStatus[]>(
      this.steuerungBaseUrl + '/bdp/status'
    );
  }

  public getExportFile(file: any) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append(
      'Content-Type',
      MediaType.jsonUtf8MediaType
    );
    const url = `${this.baseUrl}/file/${file.id}/export`;
    return this.httpClient.get(url, {
      headers: httpHeaders,
      responseType: 'blob',
    });
  }

  public getExportFileForSDP(bdpName, name): Observable<any> {
    const url = `${this.steuerungBaseUrl}/serien-bdp-history/${bdpName}/${name}/files?download=true`;
    return this.httpClient.get(url, {
      responseType: 'json',
    });
  }

  public getExportFilesAsZipDownload(sdpId: number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Accept: MediaType.zipMediaType,
      'Content-Type': MediaType.zipMediaType,
    });
    const url = `${this.baseUrl}/steuerdaten-paket/${sdpId}/files/export`;
    return this.httpClient.get(url, {
      headers: httpHeaders,
      responseType: 'arraybuffer',
    });
  }

  public getBdpReleaseQueue(): Observable<SeriesSteuerDatenQueue[]> {
    const url = `${this.steuerungBaseUrl}/bdp-release-queue`;
    return this.httpClient.get<SeriesSteuerDatenQueue[]>(url);
  }

  public postBdpReleaseQueue(
    sdpNames: string[],
    note: string,
    reason: string
  ): Observable<HttpResponse<SeriesSteuerDatenActivation>> {
    const url = `${this.steuerungBaseUrl}/bdp-release-queue`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.post<SeriesSteuerDatenActivation>(
      url,
      { sdpNames, note, reason },
      {
        responseType: 'json',
        headers: httpHeaders,
        observe: 'response',
      }
    );
  }

  public postSeriesBdpRollback(
    name: string,
    note: string,
    reason: string
  ): Observable<HttpResponse<SeriesBdpRollback>> {
    const url = `${this.steuerungBaseUrl}/serien-bdp-history/rollback`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.post<SeriesBdpRollback>(
      url,
      { name, note, reason },
      {
        responseType: 'json',
        headers: httpHeaders,
        observe: 'response',
      }
    );
  }

  public deleteSdpFromReleaseQueue(sdpId: number) {
    const url = `${this.steuerungBaseUrl}/bdp-release-queue/${sdpId}`;
    return this.httpClient.delete(url);
  }

  getSeriesEditDetails(serie: string): Observable<SeriesSteuerDatenHistory[]> {
    const url = `${this.steuerungBaseUrl}/serien-bdp-history/sdp?baureihe=${serie}&isSerienSdp=true`;
    return this.httpClient.get<SeriesSteuerDatenHistory[]>(url);
  }

  getSeriesHistory(serie: string): Observable<SeriesSteuerDatenHistory[]> {
    const url = `${this.steuerungBaseUrl}/serien-bdp-history/sdp?baureihe=${serie}&isSerienSdp=false`;

    return this.httpClient.get<SeriesSteuerDatenHistory[]>(url);
  }

  getSeriesProductiveBdp(): Observable<SeriesBdp[]> {
    const flag = true;
    const url = `${this.steuerungBaseUrl}/serien-bdp-history?isSerienBdp=${flag}`;

    return this.httpClient.get<SeriesBdp[]>(url);
  }

  getSeriesBdpHistory(
    parameters?: FilteredSeriesBdp
  ): Observable<HttpResponse<SeriesBdpResponse>> {
    const flag = false;
    const url = `${this.steuerungBaseUrl}/serien-bdp-history/pageable?isSerienBdp=${flag}`;

    let paramsVar = new HttpParams();

    if (parameters) {
      const paramsObj = {};
      Object.keys(parameters).map((key) => {
        paramsObj[key] = parameters[key];
      });
      paramsVar = new HttpParams({ fromObject: paramsObj });
    }

    return this.httpClient.get<SeriesBdpResponse>(url, {
      responseType: 'json',
      observe: 'response',
      params: paramsVar,
    });
  }

  getBdpBySerie(serie: string): Observable<BdpSerieFilter[]> {
    const url = `${this.steuerungBaseUrl}/bdp/experttool?baureihe=${serie}`;
    return this.httpClient.get<BdpSerieFilter[]>(url);
  }

  getBdpDocumentation(bdp: string): Observable<BdpDocumentation> {
    const url = `${this.steuerungBaseUrl}/bdp/${bdp}/documentation`;
    return this.httpClient.get<BdpDocumentation>(url);
  }

  public getPresignedUrlForBdpZipFile(bdpName: string) {
    const httpHeaders = new HttpHeaders({
      Accept: MediaType.zipMediaType,
      'Content-Type': MediaType.zipMediaType,
    });
    const url = `${this.calculationArchive}/${bdpName}`;
    return this.httpClient.get(url, {
      headers: httpHeaders,
      responseType: 'json',
    });
  }

  getRcddbLookup(
    sdpName: string,
    snrs?: string[],
    xentryRelease?: string
  ): Observable<RcddbLookupBr[]> {
    const url = `${this.baseUrl}/steuerdaten-paket/${sdpName}/rcddb-lookup-br?snr=${snrs}&xentryVersion=${xentryRelease}`;
    return this.httpClient.get<RcddbLookupBr[]>(url);
  }

  getRcddbLookupXentryReleases(serie: string): Observable<string[]> {
    const url = `${this.baseUrl}/rcddb-lookup-br/xentry-release/${serie}`;
    return this.httpClient.get<string[]>(url);
  }

  public getBdpVorabActivationStatus(
    bdpName: string
  ): Observable<VorabSdpActivationStatus> {
    const url = `${this.steuerungBaseUrl}/bdp/${bdpName}/status`;
    return this.httpClient.get<VorabSdpActivationStatus>(url);
  }

  public putBdpVorabActivationStatus(
    bdpName: string
  ): Observable<HttpResponse<VorabSdpActivation>> {
    const url = `${this.steuerungBaseUrl}/bdp/${bdpName}/activate`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.put<VorabSdpActivation>(
      url,
      {},
      {
        responseType: 'json',
        headers: httpHeaders,
        observe: 'response',
      }
    );
  }

  extractSerieFromFinVin(value: string): string {
    if (value) {
      value = value.substring(3, 6);
    }
    return value;
  }
}
