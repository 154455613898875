import { Component, OnInit } from '@angular/core';
import { AdminService } from '@core/services/admin.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { TCFGDionameBLDataObj } from '@shared/models/experttool/TCFGDionameBLData';
import { Page } from '@shared/models/Page';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { AppService } from '@core/services/app.service';
import { Spinner } from '@shared/models/spinner';

@Component({
  selector: 'app-fcsc-tcfgdioname-bl',
  templateUrl: './fcsc-tcfgdioname-bl.component.html',
  styleUrls: ['./fcsc-tcfgdioname-bl.component.scss']
})
export class FcscTCFGDionameBLComponent implements OnInit {

  TCFGDionameBLData: TCFGDionameBLDataObj;
  response: any;
  page = new Page();
  isNaN = Number.isNaN;
  selectedFilter!: any;
  paginationDataSetting!: any;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  responseLength: number;
  downloadedAt: string;

  constructor(
    private readonly adminService: AdminService,
    private userSettingService: UserSettingService,
    private appService: AppService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.handleSpinner();
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    (await this.userSettingService.fetchUserSetting(
      userSettingKeys.globalData.TCFGDionameBL
    ).then(
      (response: any) => {
        if (Object.keys(response).length === 0 && response.constructor === Object) {
          this.paginationDataSetting = 10;
        } else {
          this.paginationDataSetting = response
        }
      }
    )
    )
    const size  = this.paginationDataSetting;
    this.page.size = size ? size : 10;
    this.saveUserSettings();
    this.page.pageNumber = 1;
    this.getTCFGDionameBLData(this.page);
  }

  handleSpinner() {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
  }

  padWithZero(value) {
    return value < 10 ? `0${value}` : value;
  }

  getTCFGDionameBLData(page): void {
    this.adminService.getTCFGDionameBLData(page.size, page.pageNumber - 1).subscribe(
      (data) => {
        this.TCFGDionameBLData = data?.body?.content;
        this.response = data?.body;
        this.responseLength = data?.body?.content?.length;
        //TODO: console.log() to be removed post testing in German environment
        console.log(data?.body?.content[0]?.last_updated)
        console.log(new Date(data?.body?.content[0]?.last_updated))
        const downloadTime = new Date(data?.body?.content[0]?.last_updated);
        this.downloadedAt = `${this.padWithZero(downloadTime.getDate())}.${this.padWithZero(downloadTime.getMonth() + 1)}.${downloadTime.getFullYear()}, ${downloadTime.getHours()}:${downloadTime.getMinutes()}:${this.padWithZero(downloadTime.getSeconds())}`;
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
      },
      (error) => {
        console.log(error);
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
      }
    );
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.getTCFGDionameBLData(this.page);
  }

  public setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.saveUserSettings();
    this.getTCFGDionameBLData(this.page);
  }

  saveUserSettings() {
    this.userSettingService.saveUserSetting(
      userSettingKeys.globalData.TCFGDionameBL,
      this.page.size
    );
  }
}
