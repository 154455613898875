<div class="filter" *ngIf="formLoaded">
  <form [formGroup]="form" (keyup.enter)="filterRows()">
    <div>
      <div class="row row-cols-auto">
        <div class="col">
          <div class="form-group">
            <label for="baureihe">{{
              "modules.data-management.table-header.series" | transloco
            }}</label>
            <ng-select
              [searchable]="true"
              [clearable]="false"
              notFoundText= "{{ 'global.dropdown-not-found-text' | transloco }}"
              [hideSelected]="true"
              [items]="seriesList"
              bindLabel="brName"
              bindValue="brName"
              formControlName="baureihe"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.brName }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.brName }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <p></p>
      <div class="row row-cols-auto">
        <div class="col">
          <div class="form-group">
            <label for="sdp">{{ "global.sd-packet" | transloco }}</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="sdp"
              formControlName="sdp"
              placeholder="{{
                'modules.data-management.filter.input-placeholder' | transloco
              }}"
              (keyup.enter)="filterRows()"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="uploaded_at">{{
              "global.uploaddate" | transloco
            }}</label>

            <div class="input-group">
              <input
                #search
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="uploaded_at"
                name="dp"
                formControlName="uploaded_at"
                ngbDatepicker
                #d="ngbDatepicker"
                readonly
                (click)="removeFocus()"
              />
              <div class="input-group-append">
                <i class="dp" (click)="d.toggle()" class="bi bi-calendar3"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col user">
          <div class="form-group">
            <label for="username">{{
              "modules.data-management.table-header.system" | transloco
            }}</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="username"
              formControlName="username"
              placeholder="{{
                'modules.data-management.filter.input-placeholder' | transloco
              }}"
            />
          </div>
        </div>

        <div class="col">
          <div class="button-area">
            <button
              type="button"
              class="btn btn-primary"
              (click)="filterRows('latest')"
            >
              {{ "modules.data-management.filter.latest-sdp" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn-primary"
              (click)="filterRows()"
            >
              {{ "modules.data-management.filter.btn-search" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="resetFilteredRows()"
            >
              {{ "modules.data-management.filter.btn-reset" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
