import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReasonInserieActivaton } from '@core/enums/reason-inserie-activaton.enum';
import { Status } from '@core/enums/status-color.enum';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { getStatusColor } from '@core/utils/getStatusColor/getStatusColor';
import { FcscBdpInserieSelectComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-edit-series-modal/fcsc-bdp-inserie-select/fcsc-bdp-inserie-select.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { SeriesSteuerDatenMap } from '@shared/models/filetype/SeriesSteuerDaten';
import { SteuerDatenMap } from '@shared/models/filetype/SteuerDaten';
import { ModalResult } from '@shared/models/ModalResult';

@Component({
  selector: 'app-fcsc-multi-series-activation-main-modal',
  templateUrl: './fcsc-multi-series-activation-main-modal.component.html',
  styleUrls: ['./fcsc-multi-series-activation-main-modal.component.scss'],
})
export class FcscMultiSeriesActivationMainModalComponent implements OnInit {
  @ViewChild('inserie') inserie!: FcscBdpInserieSelectComponent;
  @Input() baureihe!: string;
  sortConfig = [{ prop: 'createdAt', dir: 'desc' }];
  emptyMessage =
    'modules.data-management.serien-daten.data-table.empty-table-multiple-activation';
  sdpList: SteuerDatenMap[] = [];
  existingSdpList: SteuerDatenMap[] = [];
  selectedPacketFromInsert; // type added
  updateReason!: string;
  wrongBdpReason!: string;
  otherBdpReason!: string;
  bdpInSerieForm!: UntypedFormGroup;
  serieActivationForm!: UntypedFormGroup;
  notificationTitle =
    'modules.data-management.serien-daten.modal.bdp-multi-activation-notification-title';
  showDiv = {
    current: false,
    next: false,
    last: false,
  };
  selectedFilter;

  constructor(
    public activeModal: NgbActiveModal,

    private translocoService: TranslocoService,
    private notificationService: ErrorHandlerService,
    private appService: AppService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.showDiv.current = true;
    this.showDiv.next = false;
    this.showDiv.last = false;
  }

  private resetTable() {
    const rows = this.sdpList;
    this.sdpList = [];
    setTimeout(() => {
      this.sdpList = rows;
    }, 50);
  }

   private insertInSdp() {

    if (!this.selectedPacketFromInsert || !this.selectedPacketFromInsert.length) {
      return false;
    }

  this.selectedPacketFromInsert.forEach(packet => {
    const found = this.sdpList.findIndex(sdp => sdp.baureihe === packet.baureihe);
    if (found === -1) {
      this.sdpList.push(packet);
    } else {
      this.sdpList.splice(found, 1, packet);
    }
  });

    this.notificationService.showSuccess(
      '',
      this.translocoService.translate('modules.data-management.serien-daten.modal.added-to-the-list')
    );
    return true;
  }
  public checkBaureiheDuplicates(): boolean {
    if (!this.selectedPacketFromInsert || !this.selectedPacketFromInsert.length) {
      return false;
    }
    const baureiheCount = this.selectedPacketFromInsert.reduce((acc: { [key: string]: number }, packet: any) => {
      acc[packet.baureihe] = (acc[packet.baureihe] || 0) + 1;
      return acc;
    }, {} as { [key: string]: number });

    if (Object.values(baureiheCount).some((count) => (count as number) > 1)) {
      return true;
    }
    return false;
  }

  // private insertInSdp() { commented for future reference
  //   // original
  //   // let found = this.sdpList.findIndex(
  //   //   (sdp) => sdp.baureihe === this.selectedPacketFromInsert.baureihe
  //   // );
  //   console.log("insert",this.selectedPacketFromInsert);
  //   if (found === -1) {
  //     this.sdpList.push(this.selectedPacketFromInsert);
  //     this.notificationService.showSuccess(
  //       '',
  //       this.translocoService.translate('modules.data-management.serien-daten.modal.added-to-the-list')
  //     );
  //     return true;
  //   } else {
  //     this.sdpList.splice(found, 1, this.selectedPacketFromInsert);
  //     this.notificationService.showSuccess(
  //       '',
  //       this.translocoService.translate('modules.data-management.serien-daten.modal.added-to-the-list')
  //     );
  //     return true;
  //   }
  // }

  setFilter($event) {
    if ($event) {
      this.selectedFilter = $event;
      this.existingSdpList = this.sdpList.filter(
        (sdp) => sdp.baureihe === this.selectedFilter.baureihe
      );
    } else {
      this.inserie.onRefresh();
      this.existingSdpList = [];
    }
    this.inserie.setFilter($event);

  }

  insertSdpToMainView() {

    this.insertInSdp();
  }

  deleteData(row?) {
    const findIndex = this.sdpList.findIndex((sdp) => sdp.id === row.id);
    this.sdpList.splice(findIndex, 1);
    this.resetTable();
  }

  packetSelectedEvent(event?) {
    if (event && event.length) {
      this.selectedPacketFromInsert = event;
    }
  }

  private createSerieActivationForm() {
    this.serieActivationForm = this.formBuilder.group({
      note: new UntypedFormControl(''),
      reason: new UntypedFormControl(
        this.appService.getValueConstant(
          ReasonInserieActivaton,
          ReasonInserieActivaton.DATA_UPDATE
        )
      ),
      name: new UntypedFormControl(''),
    });
  }

  public getColor(value: string): string {
    const enumValue = Status[value as keyof typeof Status];
    return getStatusColor(enumValue);
  }

  public getValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(Status[value as keyof typeof Status])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  // modal buttons

  public addNewSdpAction() {
    this.showDiv.current = false;
    this.showDiv.next = true;
  }

  public cancelAction() {
    this.activeModal.close(null);
  }

  public backToFirstView(): void {
    this.showDiv.current = true;
    this.showDiv.last = false;
    this.showDiv.next = false;
  }

  public backToSecondView() {
    this.showDiv.current = false;
    this.showDiv.last = false;
    this.showDiv.next = true;
  }

  public showActivationFormAction() {
    this.updateReason = ReasonInserieActivaton.DATA_UPDATE;
    this.wrongBdpReason = ReasonInserieActivaton.FAULTY_BDP;
    this.otherBdpReason = ReasonInserieActivaton.OTHER;

    this.showDiv.current = false;
    this.showDiv.next = false;
    this.showDiv.last = true;
    this.createSerieActivationForm();
  }

  public activateMultipleInSerieAction() {
    this.serieActivationForm
      .get('name')
      ?.setValue(this.sdpList.map((sdp) => sdp.name).join());
    const result = {
      ok: ModalResult.ACTIVATION_SUCCESSFUL,
      form: this.serieActivationForm,
    };
    this.activeModal.close(result);
  }

}
