<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="modal-header">
  <span class="modal-title col-md-6">{{ title | transloco }} {{ fin }} </span>
  <div (click)="activeModal.dismiss()" class="col-md-6 text-right">
    <span
      class="close-icon"
      icon="times"
    >
      <i class="bi bi-x"></i>
    </span>
  </div>
</div>
<div class="modal-sub-text">{{ subTitle | transloco }}</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [selected]="selected"
          [selectAllRowsOnPage]="false"
          [rows]="calculationLogFiles"
          [selectionType]="dataTableSelection"
          [sorts]="sortConfig"
          (select)="onSelect($event)"
        >
          <ngx-datatable-column
            [cellClass]="'text-center'"
            name=""
            prop="selected"
            [flexGrow]="0"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [width]="24"
          >
            <ng-template
              ngx-datatable-header-template
              let-value="value"
              let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn"
            >
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  checked="checked"
                  [checked]="allRowsSelected"
                  (change)="selectFn(!allRowsSelected)"
                />
                <span class="checkmark"></span>
              </label>
            </ng-template>
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)"
                />
                <span class="checkmark"></span>
              </label>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Request"
            prop="requestCounter"
            [flexGrow]="1.5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="Request"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="System"
            prop="system"
            [flexGrow]="2"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="System"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{
              'modules.expert-tool.result.log.datatable.file-name' | transloco
            }}"
            prop="fileName"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.expert-tool.result.log.datatable.file-name'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{
              'modules.expert-tool.result.log.datatable.file-created'
                | transloco
            }}"
            prop="upload_date"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date: "dd.MM.yy HH:mm:ss":'UTC' }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.expert-tool.result.log.datatable.file-created'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="2"
            [canAutoResize]="true"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-row="row"
              let-expanded="expanded"
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
            >
            <span 
              title="{{
                'modules.expert-tool.result.log.tooltip.download-icon' | transloco
              }}"
              (click)="exportFile(row)"
              style="margin-right: 10px;"
            >
              <i class="bi bi-download"></i>
            </span>
            <span 
                *ngIf="checkFileType(row)"
                title="{{
                  'modules.expert-tool.result.log.datatable.icon-load-log' | transloco
                }}"
                (click)="handleFileRead(row)"
              >
                <i class="bi bi-window-stack"></i>
              </span>

            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              ngx-datatable-header-template
            >
              <span
                ngbTooltip="{{
                  'global.actions' | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<!-- footer -->
<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="cancelAction()"
            >
              {{ "global.cancel" | transloco }}
            </button>

            <button
              [disabled]="!selected.length"
              type="button"
              class="btn btn btn-primary"
              (click)="handleDownload()"
            >
              {{ "global.download" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
