import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FcscAdminFsfManipulatorEntitlementComponent } from './components/fcsc-admin-fsf-manipulator-entitlement/fcsc-admin-fsf-manipulator-entitlement.component';
import { AdminService } from '@core/services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DownloadService } from '@core/services/download.service';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UserSettingService } from '@core/services/user-setting.service';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { Page } from '@shared/models/Page';
import { FSFLogsResponse } from '@shared/models/FSFLogs';
import { AppService } from '@core/services/app.service';
import { DatePipe } from '@angular/common';
import { SortInfo } from '@shared/models/SortInfo';
import { FilteredLogHistory } from '@shared/models/experttool/LogHistory';
import { Spinner } from '@shared/models/spinner';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';

@Component({
  selector: 'app-fcsc-admin-fsf-logs',
  templateUrl: './fcsc-admin-fsf-logs.component.html',
  styleUrls: ['./fcsc-admin-fsf-logs.component.scss']
})
export class FcscAdminFsfLogsComponent implements OnInit {
  logs: any;
  form!: UntypedFormGroup;
  emptyMessage!: string;
  paginationDataSetting!: any;
  sortConfig!: Array<{}>;
  page = new Page();
  response!: FSFLogsResponse | null;
  isNaN = Number.isNaN;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  selectedFilter!: FilteredLogHistory;
  @ViewChild('searchFrom') searchFrom!: ElementRef;
  @ViewChild('searchTo') searchTo!: ElementRef;

  constructor(
    private modalService: NgbModal,
    private adminService: AdminService,
    private downloadService: DownloadService,
    private formBuilder: UntypedFormBuilder,
    private translocoService: TranslocoService,
    private appService: AppService,
    private userSettingService: UserSettingService,
    private datePipe: DatePipe,
    public notificationService: ErrorHandlerService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.handleSpinner();
    this.createForm();
    this.setTranslation();
    this.refreshDataTable();
    this.retrieveDataOnPageChange(null);
  }

  handleSpinner() {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
  }

  private async refreshDataTable() {
    await this.configPageInfoAfterInit();
    this.setPage({page: 1});
  }

  private async configPageInfoAfterInit() {
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.fsflogs.fsfHistoryTable
      )) || {};

      const { sort, size } = this.paginationDataSetting;
      // Sort
      const sortData = sort || 'vin,desc';
      const sortArray = sortData.split(',');
      const orderBy = sortArray[0];
      const orderDir = sortArray[1];
      this.sortConfig = [{ prop: orderBy, dir: orderDir }];

      // Order
      this.page.orderDir = orderDir;
      this.page.orderBy = orderBy;

      // Page
      this.page.pageNumber = 1;

      // Size
      (size == 0 || size == undefined) ? this.page.size = 10 : this.page.size = size;
  }

  loadContent() {
    this.logs = (this.response && this.response?.content) || [];
  }

  setTranslation(): void {
    this.translocoService
      .selectTranslate(
        'modules.data-management.serien-daten.data-table.empty-table-series'
      )
      .subscribe((resp) => {
        this.emptyMessage = resp;
      });
  }

  createForm() {
    this.form = this.formBuilder.group({
      seriesFilter: new UntypedFormControl(''),
      vinFilter: new UntypedFormControl(''),
      usernameFilter: new UntypedFormControl(''),
      createdFromFilter: new UntypedFormControl(''),
      createdToFilter: new UntypedFormControl('')
    });
  }

  public sortCallback(sortInfo: SortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getFSFLogsWithPageConfig(this.page.size, this.page.pageNumber - 1);
    this.retrieveDataOnPageChange(this.page);
  }

  transformDate(date) {
    return date.year.toString() +
    date.month.toString().padStart(2, '0') +
    date.day.toString().padStart(2, '0');
  }


  filterFSFLogs(series, vin, username, createdAtFrom, createdAtUntil) {
    createdAtFrom ? createdAtFrom = this.transformDate(createdAtFrom): '';
    createdAtUntil ? createdAtUntil = this.transformDate(createdAtUntil): '';
    this.adminService.filterFSFLogs(series ? series : '', vin ? vin : '', username ? username : '', createdAtFrom ? createdAtFrom : '', createdAtUntil ? createdAtUntil : '').subscribe(
      (data) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.response = data.body;
        this.loadContent();
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  resetFilter() {
    this.filterFSFLogs('', '', '', '', '');
    this.getFSFLogsWithPageConfig(this.page.size, 0);
    this.form.reset();
  }

  public openModal(userEntitlements,) {
    const modalRef = this.modalService.open(FcscAdminFsfManipulatorEntitlementComponent, {
      size: 'md',
      windowClass: 'uploader-modal',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.userEntitlements = userEntitlements;
  }

  displayEntitlements(row) {
    this.openModal(row.entitlements);
  }

  downloadDecryptedFile(row) {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.adminService.getFSFLogFiles(row.s3key).subscribe(
      (data) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.exportFsfResponse(data, row.vin);
      },
      (error) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    )
  }

  private getExtention(extention: string) {
    return `.${extention}`;
  }

  exportFsfResponse(xmlData: any, vin) {
    this.downloadService.createAndDownloadFile(
      xmlData,
      vin,
      this.getExtention(expertToolConstants.general.log)
    );
  }

  filterFSFLogsByValues() {
    this.filterFSFLogs(
      this.form.getRawValue().seriesFilter,
      this.form.getRawValue().vinFilter,
      this.form.getRawValue().usernameFilter,
      this.form.getRawValue().createdFromFilter,
      this.form.getRawValue().createdToFilter
    )
  }

  private saveUserSetting(data) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.fsflogs.fsfHistoryTable,
      data
    );
  }

  private async retrieveDataOnPageChange(page) {
    const filter = this.selectedFilter || {};
    const sortConf = [this.page.orderBy, this.page.orderDir].toString();

    if (page) {
      filter.page = (+this.page.pageNumber - 1).toString();
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      this.selectedFilter = filter;
    } else {
      this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.fsflogs.fsfHistoryTable
      )) || {};
      const { sort, size } = this.paginationDataSetting;
      filter.page = '0';
      (size == 0 || size == undefined) ? filter.size = '10' : filter.size = size;
      filter.sort = sort.toString();
      this.selectedFilter = filter;
    }

    this.appService.resetDatatableScroll();

    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  /**
   * On-click of page navigation
   * @param pageInfo
   */
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.retrieveDataOnPageChange(this.page);
    this.getFSFLogsWithPageConfig(this.page.size, pageInfo.page - 1);

  }

  /**
   * On-click on entries per page
   * @param size
   */
  public setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.retrieveDataOnPageChange(this.page);
    this.getFSFLogsWithPageConfig(size, 0);
  }

  getFSFLogsWithPageConfig(size, pageNumber) {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    let createdAtFrom = this.form.getRawValue().createdFromFilter;
    let createdAtUntil = this.form.getRawValue().createdToFilter;
    let series = this.form.getRawValue().seriesFilter;
    let vin = this.form.getRawValue().vinFilter;
    let username = this.form.getRawValue().usernameFilter;
    createdAtFrom ? createdAtFrom = this.datePipe.transform(createdAtFrom, 'yyyyMMdd'): '';
    createdAtUntil ? createdAtUntil = this.datePipe.transform(createdAtUntil, 'yyyyMMdd'): '';

    this.adminService.getFSFLogsWithPageConfig(series ? series : '', vin ? vin : '', username ? username : '', createdAtFrom ? createdAtFrom : '', createdAtUntil ? createdAtUntil : '', size, pageNumber, this.page.orderBy, this.page.orderDir).subscribe(
      (data) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.response = data.body;
        this.loadContent();
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  createECUMap(ecus) {
    return ecus.map(ecu => ecu).join(', ');
  }

  removeSearchFromFromFocus() {
    this.searchFrom.nativeElement.blur();
  }

  removeSearchToFromFocus() {
    this.searchTo.nativeElement.blur();
  }

}
