import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AttributesVpRate } from '@core/enums/attributes-vp-rate.enum';
import { VpStatus } from '@core/enums/vp-status.enum';
import { AppService } from '@core/services/app.service';
import { CalculationService } from '@core/services/calculation.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { ExperttoolDataModelingService } from '@core/services/experttool-data-modeling/experttool-data-modeling.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { StorageService } from '@core/services/storage.service';
import { entitlements } from '@env/entitlements';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { specialSerie } from '@shared/lists/specialSerie';
import { AscMetadata } from '@shared/models/AscMetadata';
import { ControlUnits } from '@shared/models/ControlUnits';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { CalculateFinlog } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateFinlog';
import { CalculateKeyValGeneric } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateKeyValGeneric';
import { HeaderEtnExtraction } from '@shared/models/experttool/HeaderEtnExtraction';
import { VedocData } from '@shared/models/experttool/VedocData';
import { BdpName } from '@shared/models/filetype/BdpName';
import { ModalResult } from '@shared/models/ModalResult';
import { Spinner } from '@shared/models/spinner';
import { Subscription } from 'rxjs';
import { SharedSeriesService } from '@core/services/shared-series.service';

import { FcscFwVedocDetailsComponent } from '../fcsc-fw-vedoc-details/fcsc-fw-vedoc-details.component';
import { FcscLogFilesModalComponent } from '../fcsc-log-files-modal/fcsc-log-files-modal.component';
import { FcscResultDependenciesComponent } from '../fcsc-result-dependencies/fcsc-result-dependencies.component';

@Component({
  selector: 'app-fcsc-finlog',
  templateUrl: './fcsc-finlog.component.html',
  styleUrls: ['./fcsc-finlog.component.scss'],
})
export class FcscFinlogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() vedocData!: VedocData;
  @Input() selectedSdpName!: string;
  @Input() selectedBdpName!: string;
  @Input() selectedExtentionName!: string;
  @Input() selectedBdpModalRow!: any;
  @Input() details!: FcscFwVedocDetailsComponent;
  @Input() selectedSumsCheck: boolean;
  @Output() forwardCalculation =
    new EventEmitter<ExpertToolCalculation | null>();
  @Output() setHeaderAttribEtn = new EventEmitter<any>();
  @Output() populateData = new EventEmitter<any>();
  @Output() calculateRequestData = new EventEmitter<any>();

  form!: UntypedFormGroup;
  directLoadForm!: UntypedFormGroup;
  subscription!: Subscription;
  showDiv = false;
  isDirectLoadChecked = true;
  spinnerObject!: Spinner;
  isCalculated!: boolean;
  logDownloadEntitlement = [entitlements.VUS.FCSC_FLASHWARE_CALCULATION_EXPORT];
  metadata!: AscMetadata;
  private expertToolCalculationRequest!: ExpertToolCalculation;
  private expertToolCalculationResponse: ExpertToolCalculation | null;
  
  public headerEtn: HeaderEtnExtraction = {
    header: {},
    etn: {},
  };
  vpstatus!: string;
  waitForResponse!: boolean;
  waitForActivationResponse!: boolean;
  isSelectedBdpStatusChanged!: boolean;
  sessionId!: string;
  translatedExtentionPlaceholder!: string;
  loadingStatus!: string;
  intreval!: any;
  isEcuSelected!: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private translocoService: TranslocoService,
    private steuerdatenService: SteuerdatenService,
    private calculationService: CalculationService,
    private notificationService: ErrorHandlerService,
    private modalService: NgbModal,
    private appService: AppService,
    public expertoolDataModelingService: ExperttoolDataModelingService,
    public sharedSeriesService : SharedSeriesService
  ) {}

  ngOnInit(): void {
    this.checkBdpStatusChange();
    const selectedBdpName = this.storageService.getData(
      sessionStorageKeys.selectedBdpName
    );
    if (selectedBdpName) {
      this.startLoader();
    }

    this.spinnerObject = {
      loadingTitle: 'modules.expert-tool.loading-spinner-flashware-title',
      loadingSubtitle: 'modules.expert-tool.loading-spinner-flashware-subtitle',
      isLoading: false,
    };
    this.createDirectLoadForm();

    this.sessionId = this.calculationService.generateSessionId();

    this.appService
      .translateKey('modules.data-management.select-option')
      .subscribe((translation) => {
        this.translatedExtentionPlaceholder = translation;
      });

    this.handleEcuSelection();
  }

  ngOnChanges(changes) {
    // this.startLoader();
    this.checkBdpStatusChange();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.intreval);
  }

  startLoader(): void {
    const selectedBdpName =
      this.selectedBdpName ||
      this.storageService.getData(sessionStorageKeys.selectedBdpName);
    const selectedBdpModalRow =
      this.selectedBdpModalRow ||
      this.storageService.getData(sessionStorageKeys.selectedBdpModalRow);
    const checkBdpStatus = selectedBdpModalRow && selectedBdpModalRow.bdpStatus;
    const isVorab =
      selectedBdpName && selectedBdpName.startsWith(BdpName.VORAB);
    if (isVorab && checkBdpStatus !== 'ACTIVE') {
      this.waitForActivationResponse = true;
      const intreval = setInterval(() => {
        this.steuerdatenService
          .getBdpVorabActivationStatus(selectedBdpName)
          .subscribe(
            (resp) => {
              if (resp) {
                this.loadingStatus = resp.status;
                if (this.loadingStatus && this.loadingStatus === 'ACTIVE') {
                  this.storageService.removeItem(
                    sessionStorageKeys.selectedBdpModalRow
                  );
                  this.storageService.removeItem(
                    sessionStorageKeys.selectedBdpName
                  );
                  clearInterval(intreval);
                  this.notificationService.showSuccess(
                    '',
                    this.translocoService.translate(
                      'modules.expert-tool.modal-bdp-activation-success'
                    )
                  );
                  this.waitForActivationResponse = false;
                }
              }
            },
            (error: HttpErrorResponse) => {
              this.notificationService.handleServerErrorNotification(
                error,
                'modules.expert-tool.modal-bdp-activation-error'
              );
              this.waitForActivationResponse = false;
              this.storageService.removeItem(
                sessionStorageKeys.selectedBdpModalRow
              );
              this.storageService.removeItem(
                sessionStorageKeys.selectedBdpName
              );
            }
          );
      }, 5000);
      this.intreval = intreval;
    }
  }

  async updateFinLog(loadCalulate?: boolean, flag?: boolean) {
    const vedocData = this.storageService.getData(sessionStorageKeys.vedocData)
    this.showDiv = true;
    const flashForm =
      this.storageService.getData(sessionStorageKeys.flashForm) || {};
    const selected =
      this.storageService.getData(sessionStorageKeys.selected) || [];
    this.headerEtn = this.expertoolDataModelingService.setHeaderEtn(
      this.headerEtn,
      flashForm,
      vedocData,
      specialSerie
    );

    const finlogMeta: CalculateKeyValGeneric[] =
      this.expertoolDataModelingService.generateFinlogMetaSection(
        this.headerEtn,
        vedocData
      );

    const finlogEcus: CalculateKeyValGeneric[] | CalculateKeyValGeneric[][] =
      this.expertoolDataModelingService.generateFinlogEcusSection(
        selected,
        this.expertToolCalculationResponse
      );

    const combinedFinlogSection: CalculateFinlog =
      this.expertoolDataModelingService.generateFinlogCombinedSection(
        finlogMeta,
        finlogEcus
      );

      combinedFinlogSection.ecus.forEach(obj => {
        Object.keys(obj).forEach(each => {
          if(obj[each].key === "Vp_Rate_Modules") {
            let key3Value: string | undefined;

            const lines = obj[each].value.split('\n');
            for (const line of lines) {
              if (line.startsWith('ECU_Refer=')) {
                key3Value = line;
                break;
              }
            }
      
            if (key3Value) {
              const value = key3Value.split('=')[1];
            
              const trimmedKey3 = value.split('|')
                .map(item => item.trim())
                .join('|');
            
              const updatedStr = obj[each].value.replace(key3Value, `ECU_Refer=${trimmedKey3}`);
              
              obj[each].value = updatedStr;
            }
          }
        });
      });
    const defaultMeta: AscMetadata = this.getDefaultAscMetadata();

    this.expertToolCalculationRequest =
      this.expertoolDataModelingService.createExpertToolCalculation(
        defaultMeta,
        combinedFinlogSection
      );

    const isFileReader = this.storageService.getData(
      sessionStorageKeys.readFile
    );

    if (isFileReader || this.isCalculated) {
      const calculationData: ExpertToolCalculation =
        this.storageService.getData(sessionStorageKeys.calculationData);
      const metaData = calculationData.payload.finLog.metadata;
      const metaDataRequest =
        this.expertToolCalculationRequest.payload.finLog.metadata;
      metaData.forEach((element) => {
        const elem = metaDataRequest.find((data) => data.key === element.key);
        if (!elem) {
          metaDataRequest.push(element);
        }
      });
      this.isCalculated = false;
    }

    // Waits for user setting api to fetch flash form.
    if (loadCalulate) {
      const flashwareFlag = flag ? true : false;
      this.getFlashwareData(flashwareFlag);
    }
  }

  openLogFilesModal(): void {
    const modalRef = this.modalService.open(FcscLogFilesModalComponent, {
      size: 'lg',
      windowClass: 'details-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.fin = this.vedocData.vin
      ? this.vedocData.vin
      : this.vedocData.fin;
    modalRef.componentInstance.handleFileOpen = this.handleFileOpen.bind(this);
  }

  handleDirectLoadCheck() {
    const checkDirectLoad = this.directLoadForm.get(
      sessionStorageKeys.directLoad
    );
    checkDirectLoad?.setValue(!checkDirectLoad.value);
    this.isDirectLoadChecked = !this.isDirectLoadChecked;
  }

  handleEcuSelection() {
    const selectedEcu = this.storageService.getData(
      sessionStorageKeys.selected
    );
    if (selectedEcu && selectedEcu.length) {
      this.isEcuSelected = false;
    } else {
      this.isEcuSelected = true;
    }
  }

  getFlashwareData(flag: boolean) {
    if (flag) {
      this.waitForResponse = true;
    }

    this.storageService.removeItem(sessionStorageKeys.fwResponseData);
    this.storageService.removeItem(sessionStorageKeys.flashwareResponseRecords);

    const selectedBdpName = this.selectedBdpName
      ? this.selectedBdpName
      : this.selectedBdpModalRow?.bdpName;

    if (this.selectedSdpName && selectedBdpName) {
      this.storageService.removeItem(sessionStorageKeys.fwResponseData);
      this.storageService.removeItem(
        sessionStorageKeys.flashwareResponseRecords
      );
      const isVorab = this.selectedBdpName.startsWith(BdpName.VORAB);
      this.spinnerObject.isLoading = true;
      this.appService.spinnerSubject.next(this.spinnerObject);
      const fwCalculationApi = isVorab
        ? this.calculationService.postCalculateCustomBdp(
            this.selectedBdpName,
            this.expertToolCalculationRequest, this.selectedSumsCheck
          )
        : this.calculationService.postCalculateDefaultBdp(
            this.expertToolCalculationRequest, this.selectedSumsCheck
          );

      this.emitCalculationRequestData(this.expertToolCalculationRequest)

      fwCalculationApi.subscribe(
        (resp: HttpResponse<ExpertToolCalculation>) => {
          this.expertToolCalculationResponse = resp && resp.body;
          this.storageService.saveData(sessionStorageKeys.readFile, false);

          this.isCalculated = true;
          this.storageService.saveData(
            sessionStorageKeys.calculationData,
            this.expertToolCalculationResponse
          );

          const header =
            this.expertoolDataModelingService.findDynamicValueFromKey(
              resp.body?.payload.finLog.metadata,
              expertToolConstants.keys.header
            );
          this.vpstatus =
            this.expertoolDataModelingService.findDynamicValueFromKey(
              header as CalculateKeyValGeneric[],
              expertToolConstants.keys.vpStatus
            ) as string;

          this.forwardCalculation.emit(resp?.body);
          this.waitForResponse = false;
          this.spinnerObject.isLoading = false;
          this.appService.spinnerSubject.next(this.spinnerObject);
          this.details.clearSearchForm();
          this.details.isVedocSubSectionCollapsed = false;
          if (
            this.vpstatus === VpStatus.ECU_DEPENDENCY_LOAD_NEEDED &&
            !this.isDirectLoadChecked
          ) {
            this.openDependenciesConfirmModal(resp?.body);
          }
          if (
            this.vpstatus === VpStatus.ECU_DEPENDENCY_LOAD_NEEDED &&
            this.isDirectLoadChecked
          ) {
            setTimeout(() => {
              this.addDependency(resp?.body);
              this.updateFinLog(true, true);
            }, 500);
          } else {
            this.scrollToResult();
            this.addDependency(resp?.body, true);
          }
        },

        (error: HttpErrorResponse) => {
          this.forwardCalculation.emit(null);
          this.waitForResponse = false;
          this.details.clearSearchForm();
          this.spinnerObject.isLoading = false;
          this.appService.spinnerSubject.next(this.spinnerObject);
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.expert-tool.result.error-message'
          );
        }
      );
    }
  }

  private handleFileOpen(vedocData, fileName) {
    if (vedocData) {
      this.appService.responseFromFinlog = vedocData;
        window[sessionStorageKeys.populateData] = () => {
          return JSON.parse(JSON.stringify(vedocData));
        };
        window[sessionStorageKeys.calculationData] = () => {
          this.expertoolDataModelingService.calculationRequestData = this.storageService.getData(sessionStorageKeys.calculationData);
          return this.storageService.getData(sessionStorageKeys.calculationData);
        };
        window[sessionStorageKeys.selected] = () => {
          return this.storageService.getData(sessionStorageKeys.selected);
        };
        window[sessionStorageKeys.fileName] = () => {
          return fileName || {};
        };
        window[sessionStorageKeys.baureiheData] = () => {
          return this.expertoolDataModelingService.baureiheList || []
        }
        const url = window.location.href;
        window.open(url, '_blank');
    }
  }

  private checkBdpStatusChange(): boolean {
    this.isSelectedBdpStatusChanged = true;
    const selectedBdpModalRow =
      this.selectedBdpModalRow ||
      this.storageService.getData(sessionStorageKeys.selectedBdpModalRow);
    const checkBdpStatus = selectedBdpModalRow && selectedBdpModalRow.bdpStatus;
    if (checkBdpStatus === 'ACTIVE') {
      return (this.isSelectedBdpStatusChanged = false);
    } else {
      return (this.isSelectedBdpStatusChanged = true);
    }
  }

  private getDefaultAscMetadata() {
    const flashForm =
      this.storageService.getData(sessionStorageKeys.flashForm) || {};
    const ascMetadata = new AscMetadata(this.storageService);
    ascMetadata.sessionID = this.sessionId;
    ascMetadata.requestID = this.calculationService.generateRequestId();
    ascMetadata.doRequest = flashForm.abfragen;
    ascMetadata.version = flashForm.xentryReleases;
    return ascMetadata;
  }

  private updateFinlogMetaData(
    etn: CalculateKeyValGeneric[],
    header: CalculateKeyValGeneric[],
    attrib: CalculateKeyValGeneric[]
  ) {
    this.vedocData = this.expertoolDataModelingService.updateFinlogMetaData(
      etn,
      header,
      attrib
    );

    this.headerEtn.header = Array.isArray(header)
      ? this.expertoolDataModelingService.mapArraytoObject(header)
      : header;
    this.headerEtn.etn = Array.isArray(etn)
      ? this.expertoolDataModelingService.mapArraytoObject(etn)
      : etn;
    this.setHeaderAttribEtn.emit(this.vedocData);
  }

  addDependency(data: ExpertToolCalculation | null, flag?) {
    const etn = this.expertoolDataModelingService.findDynamicValueFromKey(
      data?.payload.finLog.metadata,
      expertToolConstants.keys.etn
    ) as CalculateKeyValGeneric[];

    const ecus = data?.payload.finLog.ecus;

    const header = this.expertoolDataModelingService.findDynamicValueFromKey(
      data?.payload.finLog.metadata,
      expertToolConstants.keys.header
    ) as CalculateKeyValGeneric[];

    const attributes =
      this.expertoolDataModelingService.findDynamicValueFromKey(
        data?.payload.finLog.metadata,
        expertToolConstants.keys.attrib
      ) as CalculateKeyValGeneric[];

    const dependencies =
      this.expertoolDataModelingService.findDynamicValueFromKey(
        etn,
        expertToolConstants.keys.ecuRequest
      ) as string;

    const newSelected: ControlUnits[] = [];
    let currentSelected = this.expertoolDataModelingService.updateEcus(
      ecus,
      this.details.orignalRows.length
    ) as ControlUnits[];

    this.updateFinlogMetaData(etn, header, attributes);
    this.updateVedocRows(currentSelected);
    this.updateVedocRowsForDependencies(
      newSelected,
      currentSelected,
      dependencies,
      flag
    );
    this.storageService.saveData(
      sessionStorageKeys.vedocRecords,
      this.details.orignalRows
    );
    const filteredSelected: ControlUnits[] = [];
    newSelected.forEach((cur) => {
      let found = false;
      currentSelected?.forEach((nw) => {
        if (nw.diogName === cur.diogName) {
          found = true;
        }
      });
      if (!found) {
        filteredSelected.push(cur);
      }
    });
    this.createDetialRows(currentSelected, filteredSelected);
  }

  private createDetialRows(currentSelected, filteredSelected) {
    currentSelected = currentSelected?.concat(filteredSelected);
    this.storageService.saveData(sessionStorageKeys.selected, currentSelected);
    this.updatePrimary(this.vedocData.controlUnits, currentSelected);
    this.updatePrimary(this.details.rows, currentSelected);
    this.updatePrimary(this.details.orignalRows, currentSelected);
    this.updatePrimary(this.details.records, currentSelected);

    this.details.orignalSelected = currentSelected;
    this.details.updateVedocDataStore();
    this.details.setSelected(true);
    this.details.records = JSON.parse(JSON.stringify(this.details.rows));
    this.details.vedocData.controlUnits = this.details.orignalRows;
    Object.assign({}, this.vedocData);
    const rows = this.details.rows;
    this.details.rows = [];
    setTimeout(() => {
      this.details.rows = rows;
    }, 200);
  }

  private updateVedocRows(currentSelected) {
    currentSelected?.forEach((element, index) => {
      const diogName = element.diogName;

      const row = this.details.rows.find((row) => row.diogName === diogName);
      const orignalRow = this.details.orignalRows.find(
        (orignalRow) => orignalRow.diogName === diogName
      );
      const record = this.details.records.find(
        (record) => record.diogName === diogName
      );

      if (row) {
        row.attributes = element.attributes;
        row.visibleCheck = element.visibleCheck;
      }
      if (orignalRow) {
        orignalRow.attributes = element.attributes;
        orignalRow.visibleCheck = element.visibleCheck;
      }
      if (record) {
        record.attributes = element.attributes;
        record.visibleCheck = element.visibleCheck;
      }

      this.details.clearSearchForm();
      this.details.filterRecords();
    });
  }

  private updateVedocRowsForDependencies(
    newSelected,
    currentSelected,
    dependencies,
    flag: boolean
  ) {
    if (!flag) {
      dependencies.split('|').forEach((dep) => {
        const orignalControlUnits: ControlUnits[] = this.storageService.getData(
          sessionStorageKeys.vedocDataCopy
        );
        const foundSelected = currentSelected.find(
          (sel) => sel.diogName === dep
        );
        orignalControlUnits?.forEach((cu, index) => {
          if (cu.diogName === dep) {
            const controlUnit: ControlUnits = {
              id: foundSelected
                ? foundSelected.id
                : this.details.orignalRows.length + index + 1,
              diogName: dep,
              swSnrs: cu.swSnrs,
              hwSnr: cu.hwSnr,
              disabled: cu.disabled,
              selected: true,
              attributes: cu.attributes,
              visibleCheck: cu.attributes
                ? cu.attributes !==
                  this.translocoService.translate(AttributesVpRate.BY_PROIRITY)
                : false,
            };

            this.checkAlreadyExist(this.vedocData.controlUnits, controlUnit);
            this.checkAlreadyExist(this.details.rows, controlUnit);
            this.checkAlreadyExist(this.details.orignalRows, controlUnit);
            this.checkAlreadyExist(this.details.records, controlUnit);
            newSelected.push(controlUnit);
          }
        });
      });
    }
  }

  private createDirectLoadForm() {
    const directLoad =
      this.storageService.getData(sessionStorageKeys.directLoad) || {};
    this.directLoadForm = this.formBuilder.group({
      directLoad: new UntypedFormControl(
        directLoad.hasOwnProperty(sessionStorageKeys.directLoad)
          ? directLoad.directLoad
          : true
      ),
    });

    this.subscription = this.directLoadForm.valueChanges.subscribe(
      (formValue) => {
        const storedForm = {
          directLoad: formValue.directLoad,
        };
        this.storageService.saveData(sessionStorageKeys.directLoad, storedForm);
      }
    );
  }

  private updatePrimary(list, subList) {
    subList.forEach((element) => {
      const found = list.find((el) => el.diogName === element.diogName);
      if (found) {
        found.id = element.id;
      }
    });
  }

  private checkAlreadyExist(list, element) {
    const foundIndex = list.findIndex((el) => el.diogName === element.diogName);
    if (foundIndex > -1) {
      const oldId = list[foundIndex].id;
      list[foundIndex] = element;
      list[foundIndex].id = oldId;
    } else {
      list.push(JSON.parse(JSON.stringify(element)));
    }
  }

  private scrollToResult() {
    document.getElementById('calculate-result')?.scrollIntoView();
  }

  private openDependenciesConfirmModal(data): void {
    this.addDependency(data, true);
    const modalRef = this.modalService.open(FcscResultDependenciesComponent, {
      size: 'lg',
      windowClass: 'dep-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.result.then((reason) => {
      this.waitForResponse = false;
      this.scrollToResult();
      if (reason === ModalResult.DEPENDENCIES_IGNORE) {
        this.updateFinLog(true, false);
      } else if (reason === ModalResult.DEPENDENCIES_EDIT) {
        this.addDependency(data);
      } else if (reason === ModalResult.DEPENDENCIES_LOAD) {
        this.addDependency(data);
        this.updateFinLog(true, true);
      }
    });
  }

  private emitCalculationRequestData(data: ExpertToolCalculation){
    this.calculateRequestData.emit(data)
  }
}
