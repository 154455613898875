<div class="outer-container">
  <div class="row" *ngIf="downloadedAt" class="TCFGDionameBLData">
    {{ 'modules.admin-section.TCFGDionameBLData.downloadTIme' | transloco }}: {{downloadedAt}}
  </div>
  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
        #dataTable
        class="material datatable-scrollable"
        [columnMode]="'flex'"
        [headerHeight]="56"
        rowHeight="auto"
        [scrollbarH]="false"
        [scrollbarV]="false"
        [rows]="TCFGDionameBLData"
        [footerHeight]="56"
        (page)="setPage($event)"
      >
          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="{{ 'global.series' | transloco }}"
            [flexGrow]="2"
            [resizeable]="false"
            prop="baureihe"
            [sortable]="false"
          >
          </ngx-datatable-column>
        <ngx-datatable-column
            [cellClass]="'text-right'"
            name="ECU"
            [flexGrow]="2"
            [resizeable]="false"
            prop="dioname"
            [sortable]="false"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="{{ 'modules.expert-tool.hwsnr' | transloco }}"
            [flexGrow]="2"
            [resizeable]="false"
            prop="hwsnr"
            [sortable]="false"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="{{ 'global.comment' | transloco }}"
            [flexGrow]="2"
            [resizeable]="false"
            prop="data_comment"
            [sortable]="false"
          >
          </ngx-datatable-column>
          <ngx-datatable-footer *ngIf="true">
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-offset="offset"
                  let-curPage="curPage"
                >
                  <div class="col-8">
                    <datatable-pager
                      [pagerLeftArrowIcon]="'datatable-icon-left'"
                      [pagerRightArrowIcon]="'datatable-icon-right'"
                      [pagerPreviousIcon]="'datatable-icon-prev'"
                      [pagerNextIcon]="'datatable-icon-skip'"
                      [count]="response?.totalElements"
                      [page]="page.pageNumber"
                      [size]="page.size"
                      (change)="setPage($event)"
                    >
                    </datatable-pager>
                  </div>

                  <div class="col-4 page-result-section server-side">
                    <span class="float-end">
                      <span class="result-text">{{
                        "components.footer.result-per-page" | transloco
                      }}</span>
                      <span
                        class="result-value"
                        [ngClass]="{ active: page.size === 10 }"
                        (click)="setPageSize(10)"
                        >10</span
                      >|
                      <span
                        class="result-value margin-override"
                        [ngClass]="{ active: page.size === 30 }"
                        (click)="setPageSize(30)"
                        >30</span
                      >|
                      <span
                        class="result-value"
                        [ngClass]="{ active: page.size === 100 }"
                        (click)="setPageSize(100)"
                        >100</span
                      >
                      <span>
                        {{ "components.footer.files" | transloco }}:
                        {{
                          response && ( response?.totalElements ) > 0
                            ? ( page.pageNumber-1 ) * page.size + 1
                            : 1
                        }}
                        -
                        {{
                          isNaN(
                            ( page.pageNumber -1  ) * page.size +
                              (page.size)
                          )
                            ? 1
                            : ( page.pageNumber-1 ) * page.size + responseLength
                        }}
                      </span>
                      <span>
                        {{ "components.footer.of" | transloco }}
                        {{
                          response?.totalElements ? response?.totalElements : 0
                        }}</span
                      >
                    </span>
                  </div>
                </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>