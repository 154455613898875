import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CalculationService } from '@core/services/calculation.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { ExperttoolDataModelingService } from '@core/services/experttool-data-modeling/experttool-data-modeling.service';
import { FileEncryptionService } from '@core/services/files/import/fileEncryption/file-encryption.service';
import { StorageService } from '@core/services/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { VedocData } from '@shared/models/experttool/VedocData';
import { FlashwareResponse } from '@shared/models/flashwareResponse';
import { FlashwareResponseInitial } from '@shared/models/flashwareResponseInitial';
import {
  FcscFsfInfoModalComponent
} from "@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-fsf-info-modal/fcsc-fsf-info-modal.component";
import {entitlements} from "@env/entitlements";
import {SecurityService} from "@core/services/security.service";
@Component({
  selector: 'app-fcsc-result-finlog',
  templateUrl: './fcsc-result-finlog.component.html',
  styleUrls: ['./fcsc-result-finlog.component.scss'],
})
export class FcscResultFinlogComponent implements OnInit {
  @Input() rows!: FlashwareResponse[];
  @Input() initialFwRes!: FlashwareResponseInitial;
  @Input() vedocData!: VedocData;
  @Input() calculationData!: ExpertToolCalculation | null;
  xmlframeFinlog!: string;
  date!: string;
  finVin!: string;
  resultCalculationData!: ExpertToolCalculation | null;
  public fsfEntitlement = [
    entitlements.VUS.FCSC_FINLOG_CRYPTO,
  ];
  fsfIsDownloadable


  constructor(
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private downloadService: DownloadService,
    private securityService: SecurityService,
    private fileEncryptionService: FileEncryptionService,
    private notificationService: ErrorHandlerService,
    private storageService: StorageService,
    public calculationService: CalculationService,
    public expertoolDataModelingService: ExperttoolDataModelingService
  ) {
    const vedoc =
      this.storageService.getData(sessionStorageKeys.vedocData) || {};
    this.finVin = vedoc.vin ? vedoc.vin : vedoc.fin;
  }

  ngOnInit(): void {
    this.setFsfIsDownloadable();
  }

  downloadFsf() {
    this.convertJsonToXmlAndEncrypt();
  }

  downloadLog() {
    this.convertJsonToIni();
  }

  private getFileName(name: string) {
    const importedName = this.fileEncryptionService.fileName.substring(
      0,
      this.fileEncryptionService.fileName.lastIndexOf('.')
    );
    return this.fileEncryptionService.fileName ? importedName : name;
  }

  private getExtention(extention: string) {
    return `.${extention}`;
  }

  /* Handle differet export Types */
  private exportFinlogResponse(iniData: any) {
    this.downloadService.createAndDownloadFile(
      iniData,
      this.getFileName('SW_' + this.finVin + '_Response'),
      this.getExtention(expertToolConstants.general.log)
    );
  }

  exportFsfResponse(xmlData: any) {
    this.downloadService.createAndDownloadFile(
      xmlData,
      this.getFileName('SW_' + this.finVin + '_Response'),
      this.getExtention(expertToolConstants.general.exf)
    );
  }

  scrollToTop() {
    document.getElementById('flashware-search')?.scrollIntoView();
  }

  scrollToFlashware() {
    document.getElementById('flashware-section')?.scrollIntoView();
  }

  private selectEcus() {
    const calculationData = JSON.parse(JSON.stringify(this.calculationData));
    const ecus = calculationData.payload?.finLog.ecus;
    const selectedEcus = ecus?.filter((ecu: any) => {
      const idEcu = ecu.find((ec) => ec.key === 'id');
      const row = this.rows.find((rw) => rw.id === idEcu?.value);
      return !!(row && row.selected);
    });
    if (selectedEcus && calculationData && calculationData.payload) {
      calculationData.payload.finLog.ecus = selectedEcus;
      this.resultCalculationData =
        calculationData as ExpertToolCalculation | null;
    }
  }

  removeCertainErrMsgs(obj) {
    obj.payload.finLog.ecus.forEach((ecu: any) => {
      ecu.forEach(element => {
        if(element.key === "ERR_MSG") {
          if(element.value === "unfulfillable_tr" || element.value === "hw_not_found") {
            element.value = "";
          }
        }
      });
    })
    return obj;
  }

  private convertJsonToIni() {
    this.selectEcus();

    let resultCalculationData = this.resultCalculationData;
    if(this.storageService.getData(sessionStorageKeys.removeCertainErrMsgs) === true) {
      resultCalculationData = this.removeCertainErrMsgs(resultCalculationData);
    }
    
    resultCalculationData?.payload.finLog.ecus.forEach(obj => {
      Object.keys(obj).forEach(each => {
        if(obj[each].key === "ECU_Refer") {
          let key3Value: string = obj[each].value;
          if (key3Value) {
            const value = key3Value;
            const trimmedKey3 = value.split('|')
              .map(item => item.trim())
              .join('|');
          
            obj[each].value = trimmedKey3;
          }
        }
      });
    });
    
    this.calculationService
      .putDownloadAsIni(resultCalculationData)
      .subscribe(
        (response) => {
          this.exportFinlogResponse(response?.body);
        },
        (error) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.expert-tool.encrypt-error'
          );
        }
      );
  }

  private convertJsonToXmlAndEncrypt() {
    this.selectEcus();
    const userEntitlements = this.securityService.getEntitlements();
    const parameters = {vin : this.vedocData.vin}
    let endPoint : any
    if (!userEntitlements.includes(entitlements.VUS.FCSC_FSF_MANIPULATION)) {
       endPoint = this.calculationService.fetchEndPointForCalculationToFsf(false);

      let calculationRequestData = this.expertoolDataModelingService.calculationRequestData;
      if(this.storageService.getData(sessionStorageKeys.removeCertainErrMsgs) === true) {
        calculationRequestData = this.removeCertainErrMsgs(calculationRequestData);
      }
      calculationRequestData?.payload.finLog.ecus.forEach(obj => {
        Object.keys(obj).forEach(each => {
          if(obj[each].key === "Vp_Rate_Modules") {
            let key3Value: string | undefined;

            const lines = obj[each].value.split('\n');
            for (const line of lines) {
              if (line.startsWith('ECU_Refer=')) {
                key3Value = line;
                break;
              }
            }
      
            if (key3Value) {
              const value = key3Value.split('=')[1];
            
              const trimmedKey3 = value.split('|')
                .map(item => item.trim())
                .join('|');
            
              const updatedStr = obj[each].value.replace(key3Value, `ECU_Refer=${trimmedKey3}`);
              
              obj[each].value = updatedStr;
            }
          }
        });
      });
      this.calculationService.postCalculationToFsf(endPoint, calculationRequestData,parameters).subscribe(
        (response) => {
          const data = atob(response?.body.content +'')
          this.exportFsfResponse(data);
        }
      )
    }else {
      endPoint = this.calculationService.fetchEndPointForCalculationToFsf(true);

      let resultCalculationData = this.resultCalculationData;
      if(this.storageService.getData(sessionStorageKeys.removeCertainErrMsgs) === true) {
        resultCalculationData = this.removeCertainErrMsgs(resultCalculationData);
      }
      resultCalculationData?.payload.finLog.ecus.forEach(obj => {
        Object.keys(obj).forEach(each => {
          if(obj[each].key === "ECU_Refer") {
            let key3Value: string = obj[each].value;
            if (key3Value) {
              const value = key3Value;
              const trimmedKey3 = value.split('|')
                .map(item => item.trim())
                .join('|');
  
              obj[each].value = trimmedKey3;
            }
          }
        });
      });
      this.calculationService.postCalculationToFsf(endPoint, resultCalculationData, parameters)
        .subscribe(
          (response) => {
            const base64response = response.body.content + '';
            const encryptData = atob(base64response);
            this.exportFsfResponse(encryptData);
          },
          (error) => {
            this.notificationService.handleServerErrorNotification(
              error,
              'modules.expert-tool.encrypt-error'
            );
          }
        );
    }
  }

  openFsfInfoModal() {
    this.modalService.open(FcscFsfInfoModalComponent, {
      size: 'xl',
      windowClass: 'confirmation-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  private setFsfIsDownloadable() {
    const entitlements = this.securityService.getEntitlements();
    this.fsfIsDownloadable = this.fsfEntitlement.some((item) => entitlements.includes(item));
    return this.fsfIsDownloadable
  }
}
