import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { entitlements } from '@env/entitlements';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { SeriesBdpHistoryFile } from '@shared/models/filetype/SeriesBdp';
import { Spinner } from '@shared/models/spinner';
@Component({
  selector: 'app-fcsc-serien-bdp-history-details-modal',
  templateUrl: './fcsc-serien-bdp-history-details-modal.component.html',
  styleUrls: ['./fcsc-serien-bdp-history-details-modal.component.scss'],
})
export class FcscSerienBdpHistoryDetailsModalComponent implements OnInit {
  sortConfig = [{ prop: 'createdAt', dir: 'desc' }];
  @Input() bdpName!: string;
  seriesBdpHistoryFiles: SeriesBdpHistoryFile[] = [];
  filteredSeriesBdpHistoryFiles: SeriesBdpHistoryFile[] = [];
  searchForm!: UntypedFormGroup;
  formLoaded!: boolean;
  isLoading: boolean = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  public fileExportEntitlement = [entitlements.VUS.FCSC_SERIEN_DATA_EXPORT];

  constructor(
    public activeModal: NgbActiveModal,
    private steuerdatenService: SteuerdatenService,
    private downloadService: DownloadService,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private formBuilder: UntypedFormBuilder,
    private dataPipe: DatePipe,
    private notificationService: ErrorHandlerService,
    private appService: AppService,
  ) {}

  ngOnInit(): void {
    this.spinnerObject = {
      loadingTitle: 'global.download-in-progress',
      loadingSubtitle: '',
      isLoading: false,
    };
    this.appService?.spinnerSubject?.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
    this.getSteuerDatenPaketFiles(this.bdpName);
    this.createSearchForm();
  }

  private createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      searchField: new UntypedFormControl(''),
    });
    this.formLoaded = true;
  }

  private getSteuerDatenPaketFiles(seriesBdpName: string): void {
    this.steuerdatenService
      .getBdpHistoryFiles('SERIEN_' + seriesBdpName)
      .subscribe(
        (data: SeriesBdpHistoryFile[]) => {
          this.seriesBdpHistoryFiles = data;
          this.filteredSeriesBdpHistoryFiles = [...data];
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message'
          );
        }
      );
  }

  filterRecords(): void {
    const searchValue: string = this.searchForm.get('searchField')?.value;
    const filteredRows: SeriesBdpHistoryFile[] =
      this.seriesBdpHistoryFiles.filter((row: any) => {
        return row.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    this.filteredSeriesBdpHistoryFiles = [...filteredRows];
  }

  resetFilteredRows() {
    this.searchForm.get('searchField')?.setValue('');
    this.getSteuerDatenPaketFiles(this.bdpName);
  }

  public exportFile(file: SeriesBdpHistoryFile) {
    this.spinnerObject.isLoading = true;
    this.appService?.spinnerSubject?.next(this.spinnerObject);
    this.steuerdatenService.getExportFileForSDP('SERIEN_' + this.bdpName, file.name)
    .subscribe(response => {
      window.open(response[0], "_blank")
      this.spinnerObject.isLoading = false;
      this.appService?.spinnerSubject?.next(this.spinnerObject); 
    },
    (error: HttpErrorResponse) => {
      this.notificationService.handleServerErrorNotification(error, '');
      this.spinnerObject.isLoading = false;
    this.appService?.spinnerSubject?.next(this.spinnerObject);
    });
  }
}
