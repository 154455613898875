<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="outer-container">
  <div class="row">
    <div class="col-12 round-button-container">
      <span 
        title="{{
          'global.download-csv' | transloco
        }}"
        (click)="downloadCSV()"
        class="main-icon"
      >
        <i class="bi bi-download bi-download-1"></i>
      </span>
    </div>
      <div class="col-12">
        <div class="vus-container">
          <ngx-datatable
            #dataTable
            class="material datatable-scrollable"
            [columnMode]="'flex'"
            [headerHeight]="56"
            rowHeight="auto"
            [scrollbarH]="false"
            [scrollbarV]="false"
            [rows]="steuerDaten"
            [sorts]="sortConfig"
          >
            <ngx-datatable-column
              name="{{ 'global.series' | transloco }}"
              prop="baureihe"
              [flexGrow]="3"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.series'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'global.sd-packet' | transloco }}"
              prop="name"
              [flexGrow]="8"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <span
                  class="sdpaket"
                  *ngIf="value"
                  (click)="sdPaketsDetails(row, value)"
                  placement="bottom"
                  ngbTooltip="{{
                    'modules.data-management.tooltip.sd-packet'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  tooltipClass="note-tooltip"
                  >{{ value }}</span
                >
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.data-management.table-header.sd-packet'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'global.uploaddate' | transloco }}"
              prop="createdAt"
              [flexGrow]="5"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value | date: "dd.MM.yy  HH:mm:ss":'UTC' }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.upload-date'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{
                'modules.data-management.serien-daten.data-table.inSeriesSince'
                  | transloco
              }}"
              prop="inSeriesSince"
              [flexGrow]="5"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{
                  value | date: "dd.MM.yy, HH:mm:ss":'UTC'
                }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.serien-daten.data-table.inSeriesSince'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [cellClass]="'text-right'"
              name="User"
              prop="username"
              [flexGrow]="5"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.system'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'global.comment' | transloco }}"
              prop="note"
              [flexGrow]="7"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span
                  *ngIf="value"
                  class="text-pre-wrap"
                  placement="bottom"
                  [ngbTooltip]="value"
                  data-container="body"
                  container="body"
                  tooltipClass="note-tooltip"
                  >{{ truncateNote(value) }}</span
                >
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.comment'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *appRequiredEntitlements="zipExportEntitlement"
              name="{{ 'global.actions' | transloco }}"
              [flexGrow]="4"
              [sortable]="false"
              [resizeable]="false"
            >
              <ng-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <span 
                  [ngClass]="{'no-drop-cursor': !row.steuerDatenPaketId}"
                  title="{{!row.steuerDatenPaketId ? ('modules.data-management.tooltip.download-no-sdp' | transloco) :
                    ('modules.data-management.tooltip.download' | transloco)
                  }}"
                  >
                  <span 
                    [ngClass]="{'disabled': !row.steuerDatenPaketId}"
                    *appRequiredEntitlements="zipExportEntitlement"
                  (click)="exportSdpFiles(row)"
                  >
                    <i class="bi bi-download"></i>
                  </span>
                </span>
                <span 
                  *appRequiredEntitlements="zipExportEntitlement"
                  title="{{
                    'modules.expert-tool.tooltip.edit-row' | transloco
                 }}"
                 (click)="editData(row)"
                >
                  <i class="bi bi-pencil-fill"></i>
                </span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                ngx-datatable-header-template
              >
                <span
                  ngbTooltip="{{
                    'global.actions' | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="footer-counter">
          <span class="counter"> {{ "components.footer.files" | transloco }}: {{count}} </span>
        </div>
      </div>

  </div>
</div>
<p></p>
<div class="col">
  <button
    type="button"
    class="btn btn-secondary justify"
    (click)="openActivateMultipleSeries()"
  >
    {{ "modules.data-management.serien-daten.modal.bdp-multi-activation-btn" | transloco }}
  </button>
</div>

<app-fcsc-bdp-series-queue
  *ngIf="isQueueExisting()"
  (refreshTable)="refreshTable($event)"
  [steuerDatenQueue]="steuerDatenQueue"
></app-fcsc-bdp-series-queue>
