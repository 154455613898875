<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<ng-container [formGroup]="form" *ngIf="vedocAbfragenEntitlements">
  <div id="flashware-search" class="outer-container first">
    <div class="load-vin">
      <div class="row row-cols-auto">
        <div class="col">
          <div class="form-group">
            <label for="vinfin">FIN / VIN</label>
            <input
              [ngbTypeahead]="search"
              (focus)="focus$.next($any($event).target.value)"
              (click)="click$.next($any($event).target.value)"
              (blur)="handleBlur()"
              *appRequiredEntitlements="vedocAbfragenEntitlements"
              type="text"
              autocomplete="off"
              class="form-control vin-loader"
              id="vinfin"
              placeholder="{{
                'modules.expert-tool.fin-placeholder' | transloco
              }}"
              formControlName="vin"
              [class.is-invalid]="isInputInvalid('vin')"
              (keyup)="handleChange($event)"
            />
          </div>
        </div>

        <div class="col">
          <button
            *appRequiredEntitlements="vedocAbfragenEntitlements"
            class="btn btn-primary justify"
            (click)="loadDataFromVedoc()"
            [disabled]="!form.get('vin')?.value || form.invalid"
          >
            {{ "modules.expert-tool.btn-load-vehicle-data" | transloco }}
          </button>
        </div>

        <div
          *appRequiredEntitlements="vedocAbfragenEntitlements"
          class="col finlog-read"
        >
          <app-fcsc-finlog-data-reader
            formGroupName="finlogDataReader"
            (populateData)="populateReadedFinlogFileData($event)"
          ></app-fcsc-finlog-data-reader>
        </div>

        <div class="col right scroll-to-section" *ngIf="calculationData">
          <span class="float-to-right"
            >{{ "global.scroll-to-result" | transloco }}
            <span
              (click)="scrollToResult()"
            >
              <i class="bi bi-chevron-double-down"></i>
            </span>
          </span>
        </div>
        <div *ngIf="form.invalid || vinFromQueryParamsInvalid" class="mb-3">
          <app-upload-duration
            class="margin"
            [isError]="true"
            [translationKey]="'modules.expert-tool.fin-validation'"
          ></app-upload-duration>
        </div>
      </div>
    </div>
  </div>
  <app-fcsc-fw-vedoc-details
    #details
    *ngIf="showDiv"
    [vedocData]="vedocData"
    (handleSelection)="handleSelection()"
  ></app-fcsc-fw-vedoc-details>

  <app-fcsc-flashware
    *ngIf="showDiv"
    [vedocData]="vedocData"
    [finlog]="finlog"
    [selectedSdpInput]="selectedSdpName"
    (selectSdpName)="selectSdpName($event)"
    [selectedBdpName]="selectedBdpName"
    (selectBdpName)="selectBdpName($event)"
    (selectedExtention)="selectedExtention($event)"
    [selectedBdpModalRow]="selectedBdpModalRow"
    (selectBdpModalRow)="selectBdpModalRow($event)"
    (selectedSumsCheck)="selectSumsCheck($event)"
    (xentryReleaseList) = "handleXentryReleaseApiData($event)"
    [fromReader]="fromReader"
  >
  </app-fcsc-flashware>

  <app-fcsc-finlog
    #finlog
    *ngIf="showDiv"
    [vedocData]="vedocData"
    [selectedSdpName]="selectedSdpName"
    [selectedBdpName]="selectedBdpName"
    [selectedExtentionName]="selectedExtentionName"
    [selectedBdpModalRow]="selectedBdpModalRow"
    [selectedSumsCheck]="selectedSumsCheck"
    (forwardCalculation)="forwardCalculation($event)"
    (setHeaderAttribEtn)="setHeaderAttribEtn($event)"
    [details]="details"
    (populateData)="populateReadedFinlogFileData($event)"
    (calculateRequestData)="handleCalculationRequestDataEvent($event)"
  >
  </app-fcsc-finlog>
  <app-fcsc-calculate
    *ngIf="calculationData && showDiv"
    [vedocData]="vedocData"
    [calculationData]="calculationData"
    [fromReader]="fromReader"
    [xentryReleasesData] = "xentryReleaseData"
  >
  </app-fcsc-calculate>
</ng-container>
