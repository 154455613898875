import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StatusAdminVp5 } from '@core/enums/status-vp5.enum';
import { AdminService } from '@core/services/admin.service';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { entitlements } from '@env/entitlements';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmatonModalComponent } from '@shared/components/confirmaton-modal/confirmaton-modal.component';
import { AdminVp5VersionsConfig } from '@shared/models/filetype/AdminVp5VersionsConfig';
import { ModalResult } from '@shared/models/ModalResult';
import { Spinner } from '@shared/models/spinner';

@Component({
  selector: 'app-fcsc-admin-vp5-versions',
  templateUrl: './fcsc-admin-vp5-versions.component.html',
  styleUrls: ['./fcsc-admin-vp5-versions.component.scss'],
})
export class FcscAdminVp5VersionsComponent implements OnInit {
  public VP5VersionEntitlement = [entitlements.VUS.FCSC_VP5_VERSION];
  sortConfig = [{ prop: 'version', dir: 'desc' }];
  vp5VerionsData: AdminVp5VersionsConfig[] | null;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  activeStatus!: string;

  constructor(
    private adminService: AdminService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    private appService: AppService
  ) {
    this.activeStatus = this.appService.getValueConstant(
      StatusAdminVp5,
      StatusAdminVp5.IN_USE
    );
  }

  ngOnInit(): void {
    this.handleInitialSpinner();
    this.fetchVp5VersionsData();
  }

  activateVp5Version(version: string): void {
    const modalRef = this.modalService.open(ConfirmatonModalComponent, {
      size: 'lg',
      windowClass: 'confirmation-modal',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.admin-section.vp-versions.modal-confrim.title'
    );
    modalRef.componentInstance.subText = this.translocoService.translate(
      'modules.admin-section.vp-versions.modal-confrim.sub-title',
      { name: version }
    );
    modalRef.componentInstance.buttonText = this.translocoService.translate(
      'modules.admin-section.vp-versions.modal-confrim.btn-confirm'
    );
    modalRef.componentInstance.buttonSecondaryText =
      this.translocoService.translate('global.cancel');

    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.DELETE_OK) {
        this.adminService.putVp5ActivateVesrion(version).subscribe(
          () => {
            this.notificationService.showSuccess(
              '',
              this.translocoService.translate(
                'modules.admin-section.vp-versions.modal-confrim.success',
                { name: name }
              )
            );

            this.fetchVp5VersionsData();
          },
          (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.notificationService.handleServerErrorNotification(
              error,
              'modules.expert-tool.result.error-message'
            );
          }
        );
      }
    });
  }

  getVP5ActivationStatusValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusAdminVp5[value as keyof typeof StatusAdminVp5])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  private fetchVp5VersionsData(): void {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.adminService.getAdminVp5Versions().subscribe(
      (data: HttpResponse<AdminVp5VersionsConfig[]>) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.vp5VerionsData = data.body;
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.result.error-message'
        );
      }
    );
  }

  private handleInitialSpinner() {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };
    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
  }
}
