import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output,  OnChanges, SimpleChanges } from '@angular/core';
import { StatusBdp } from '@core/enums/status-bdp.enum';
import { Status } from '@core/enums/status-color.enum';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { getStatusColor } from '@core/utils/getStatusColor/getStatusColor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { BdpStatus } from '@shared/models/filetype/BdpStatus';
import { DataPool } from '@shared/models/filetype/Datapool';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  FilteredSteuerDaten,
  SteuerDaten,
  SteuerDatenMap,
} from '@shared/models/filetype/SteuerDaten';
import { Page } from '@shared/models/Page';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { SelectionType } from '@swimlane/ngx-datatable';
import { CheckBoxSelect } from '@shared/models/Select';

@Component({
  selector: 'app-fcsc-bdp-inserie-select',
  templateUrl: './fcsc-bdp-inserie-select.component.html',
  styleUrls: ['./fcsc-bdp-inserie-select.component.scss'],
})
export class FcscBdpInserieSelectComponent implements OnInit {
  @Input() baureihe!: string;
  @Input() loadFlag!: boolean;
  @Input() smokeTestStausPassed = this.appService.getValueConstant(
    Status,
    Status.SMOKE_TEST_PASSED
  );
  @Input() filteredData: SteuerDatenMap[] = [];
  @Output() selectedPacket: EventEmitter<any> = new EventEmitter();
  page = new Page();
  sortConfig!: Array<{}>;
  paginationDataSetting!: any;
  params: GeneralPageInfo | undefined;
  steuerDaten: SteuerDatenMap[] = [];
  selected: SteuerDatenMap[] = [];
  dataTableSelection = SelectionType.checkbox;
  emptyMessage!: string;
  finalSelectedMap = {};
  isLoading = false;
  selectedFilter!: FilteredSteuerDaten;
  response!: any;
  form!: UntypedFormGroup;
  selectedFilterCheckBox!: FilteredSteuerDaten;
  isNaN = Number.isNaN;

  constructor(
    public activeModal: NgbActiveModal,
    private steuerdatenService: SteuerdatenService,
    private translocoService: TranslocoService,
    private notificationService: ErrorHandlerService,
    private appService: AppService,
    private userSettingService: UserSettingService
  ) {}

  onSelect(event: any): void {
    this.selected = [...event.selected];
    this.selectedPacket.emit(this.selected);
  }

  private clearSelectedEvents(): void {
    this.selected = [];
    this.selectedPacket.emit(this.selected);
  }

  onRefresh(): void {
    this.clearSelectedEvents();
    this.refreshDataTable();
  }

  ngOnInit(): void {
    if (!this.loadFlag) {
      this.refreshDataTable();
      // this.getSteuerDatenWithoutBdp(DataPool.VORAB); // for mock-server
    }
    this.setTranslation();
  }



   public getLatestSdps(dataPool: string, params?: FilteredSteuerDaten): void {
     console.log("Getting latestSdpFlag from datamanager")
     this.steuerdatenService.getLatestSteuerDaten(dataPool,params).subscribe(
           (data: SteuerDaten[]) => {
             this.response = data;
             this.steuerDaten = data;
           },
           (error: HttpErrorResponse) => {
             this.notificationService.handleServerErrorNotification(
               error,
               'modules.admin-section.config-modal.insert-edit-api-error-message'
             );
           }
         );
  }

  private getSteuerDatenWithoutBdp(
    dataPool: string,
    params?: FilteredSteuerDaten
  ): void {
    if (this.loadFlag && !params?.baureihe && !params?.sdp && !params?.username && !params?.uploaded_at) {
       return;
    }
    

    this.steuerdatenService.getSteuerDaten(dataPool, params).subscribe(
      (data: SteuerDaten[]) => {
        this.response = data;
        this.steuerDaten = data && data['content'];
        if (data && data['content'] && data['content'].baureihe) {
          this.baureihe = data['content'].baureihe;
        }

        const sorted = this.steuerDaten?.sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
        );
        // if (sorted) {
        //   const autoSelected = [sorted[0]];
        //   this.selected = [...autoSelected];
        //   this.selectedPacket.emit(this.selected);
        // } commented for future reference
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  onSort(event: Event): void {
    this.selected = [...this.selected];
  }

  cancel(): void {
    this.activeModal.close(null);
  }

  save(): void {
    this.activeModal.close(this.selected);
  }

  public getColor(value: string): string {
    const enumValue = Status[value as keyof typeof Status];
    return getStatusColor(enumValue);
  }

  public getValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(Status[value as keyof typeof Status])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  public getBDPValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusBdp[value as keyof typeof StatusBdp])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  // This is called when translation is triggered.
  setTranslation(): void {
    this.translocoService
      .selectTranslate(
        'modules.data-management.serien-daten.data-table.empty-table-series'
      )
      .subscribe((resp) => {
        this.emptyMessage = resp;
      });
  }

  public truncateNote(value: string): string {
    const isExpanded = value.length > 250;
    return value.substring(0, 250) + (isExpanded ? '...' : '');
  }

  /*  pagination funcutionality */

  private async refreshDataTable() {
    await this.configPageInfoAfterInit();
    if (this.baureihe) {
      this.getSteuerDatenWithoutBdp(DataPool.VORAB, this.params);
    }
  }

  private getDataOnPageChange(page) {
    const filter = this.selectedFilter || {};
    const sortConf = [this.page.orderBy, this.page.orderDir].toString();
    if (page) {
      filter.page = (+this.page.pageNumber - 1).toString();
      filter.size = this.page.size.toString();
      filter.status = this.smokeTestStausPassed;
      filter.sort = sortConf.toString();
      if (!this.loadFlag) {
        filter.baureihe = this.baureihe;
      }

      this.selectedFilter = filter;
    } else {
      filter.page = '0';
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      filter.status = this.smokeTestStausPassed;
      if (!this.loadFlag) {
        filter.baureihe = this.baureihe;
      }
      this.selectedFilter = filter;
    }
    this.appService.resetDatatableScroll();
    this.getSteuerDatenWithoutBdp(DataPool.VORAB, filter);
    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  public async setFilter(event: FilteredSteuerDaten) {
    if (!event) {
      this.steuerDaten = [];
    }
    const filter = this.selectedFilter || {};
    await this.configPageFilterInfoAfterInit(filter);
    filter.baureihe = event && event.baureihe ? event.baureihe : '';
    filter.sdp = event && event.sdp ? event.sdp : '';
    filter.username = event && event.username ? event.username : '';
    filter.uploaded_at = event && event.uploaded_at ? event.uploaded_at : '';
    filter.status = this.smokeTestStausPassed;
    this.selectedFilter = { ...this.selectedFilter, ...filter };
    
    if(event.latest === true){
      this.getLatestSdps(DataPool.VORAB, filter);
    }else
      this.getSteuerDatenWithoutBdp(DataPool.VORAB, filter);
  }

  public setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.getDataOnPageChange(null);
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.getDataOnPageChange(this.page);
  }

  public sortCallback(sortInfo: SortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getDataOnPageChange(this.page);
  }

  // Saves user data on user interaction on sort, filter and page change.
  private saveUserSetting(data) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.serien.inSerieActivationTable,
      data
    );
  }

  // Gets the user data or loads default ones.
  private async configPageInfoAfterInit() {
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.serien.inSerieActivationTable
      )) || {};

    const { sort, size } = this.paginationDataSetting;
    // Sort
    const sortData = sort || 'createdAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Order
    this.page.orderDir = orderDir;
    this.page.orderBy = orderBy;

    // Page
    this.page.pageNumber = 1;

    // Size
    this.page.size = this.page.size = size || 30;

    const sortConfArr = [this.page.orderBy, this.page.orderDir].toString();

    this.params = {
      page: '0',
      size: size || '30',
      sort: sortConfArr,
      baureihe: this.baureihe,
      status: this.smokeTestStausPassed,
    };
  }

  private async configPageFilterInfoAfterInit(filter: any) {
    await this.configPageInfoAfterInit();
    const { sort, size } = this.paginationDataSetting;
    const sortData = sort || 'createdAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    const sortConfigFilter = [orderBy, orderDir];
    filter.page = '0';
    filter.size = size || '30';
    filter.sort = sortConfigFilter.toString();
    filter.status = this.smokeTestStausPassed;
    if (!this.loadFlag) {
      filter.baureihe = this.baureihe;
    }
  }
}
