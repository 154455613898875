import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import {
  AdminSeriesConfig,
  AdminSeriesRequest,
  AdminVehicleCodeRequest,
} from '@shared/models/filetype/AdminSeriesConfig';
import { AdminVp5VersionsConfig } from '@shared/models/filetype/AdminVp5VersionsConfig';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private steuerungBaseUrl = '';
  private berechnungBaseUrl = '';
  private dataManagerBaseUrl = '';
  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.steuerungBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}/api`;
    this.berechnungBaseUrl = `${this.envService.apiUrl}${apiPaths.fcscBerechnung}/api/v1`;
    this.dataManagerBaseUrl = `${envService.apiUrl}${apiPaths.fcscDatamanager}/api`;
  }

  getSeriesConfig(): Observable<AdminSeriesConfig[]> {
    const url = `${this.steuerungBaseUrl}/baureihe`;
    return this.httpClient.get<AdminSeriesConfig[]>(url);
  }

  getSerieSmoketestFinLog(file: AdminSeriesConfig): Observable<any> {
    const headers = new HttpHeaders({});
    const url = `${this.steuerungBaseUrl}/baureihe/${file?.id}/smoke-test-finlog`;
    return this.httpClient.get(url, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  public createSerie(payload: AdminSeriesRequest) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = `${this.steuerungBaseUrl}/baureihe`;
    return this.httpClient.post(url, payload, {
      headers: headers,
      responseType: 'json',
    });
  }

  public updateSerie(id: number, payload: AdminSeriesRequest) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = `${this.steuerungBaseUrl}/baureihe/${id}`;
    return this.httpClient.put(url, payload, {
      headers: headers,
      responseType: 'json',
    });
  }

  public deleteSerie(id: number): Observable<AdminSeriesConfig> {
    const url = `${this.steuerungBaseUrl}/baureihe/${id}`;
    return this.httpClient.delete<AdminSeriesConfig>(url)
  }

  public putVehicleCodes(payload: AdminVehicleCodeRequest) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = `${this.steuerungBaseUrl}/vehicle-code`;
    return this.httpClient.put(url, payload, {
      headers: headers,
      responseType: 'json',
    });
  }

  getVehicleCodesFile(): Observable<any> {
    const headers = new HttpHeaders({});
    const url = `${this.steuerungBaseUrl}/vehicle-code/export`;
    return this.httpClient.get(url, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  getAdminVp5Versions(): Observable<HttpResponse<AdminVp5VersionsConfig[]>> {
    const headers = new HttpHeaders({});
    const url = `${this.steuerungBaseUrl}/vp5-version`;

    return this.httpClient.get<AdminVp5VersionsConfig[]>(url, {
      responseType: 'json',
      observe: 'response',
    });
  }

  putVp5ActivateVesrion(
    name: string
  ): Observable<HttpResponse<AdminVp5VersionsConfig>> {
    const url = `${this.steuerungBaseUrl}/vp5-version/${name}`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.put<AdminVp5VersionsConfig>(url, '', {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }

  getFSFLogs(): Observable<any> {
    const url = `${this.berechnungBaseUrl}/fsfLogs`;
    return this.httpClient.get<any>(url, {
      responseType: 'json',
      observe: 'response',
    });
  }

  getFSFLogsWithPageConfig(series, vin, username, createdFrom, createdTo, size, pageNumber, orderBy, orderDir): Observable<any> {
    const url = `${this.berechnungBaseUrl}/fsfLogs?series=${series}&vin=${vin}&username=${username}&createdAtFrom=${createdFrom}&createdAtUntil=${createdTo}&page=${pageNumber}&size=${size}&sort=${orderBy},${orderDir}`;
    return this.httpClient.get<any>(url, {
      responseType: 'json',
      observe: 'response',
    });
  }

  filterFSFLogs(series, vin, username, createdFrom, createdTo): Observable<any> {
    const url = `${this.berechnungBaseUrl}/fsfLogs?series=${series}&vin=${vin}&username=${username}&createdAtFrom=${createdFrom}&createdAtUntil=${createdTo}`;
    return this.httpClient.get<any>(url, {
      responseType: 'json',
      observe: 'response',
    });
  }

  getFSFLogFiles(s3_key): Observable<any> {
    const s3keyParams = s3_key.split('/');
    const url = `${this.berechnungBaseUrl}/fsfLogs/files?system=${s3keyParams[0]}&vin=${s3keyParams[1]}&shortid=${s3keyParams[2]}&filename=${s3keyParams[3]}`;
    return this.httpClient.get(url, {
      responseType: 'text'
    });
  }

  getTCFGDionameBLData(size, pageNumber): Observable<any> {
    const url = `${this.dataManagerBaseUrl}/v1/non-compatible-ecus/viewList?page=${pageNumber}&size=${size}`;
    return this.httpClient.get(url, {
      responseType: 'json',
      observe: 'response',
    });
  }
}
