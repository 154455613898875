import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { GlobalHttpInterceptor } from '@core/interceptors/global-http-interceptor';
import { AuthInterceptor } from '@core/interceptors/http.interceptor';
import { AppService } from '@core/services/app.service';
import { EnvService } from '@core/services/env.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { NgbDateCustomParserFormatter } from '@core/utils/customDateFormat/custom-datepicker-formatter';
import { NgbActiveModal, NgbDateParserFormatter, NgbDatepickerI18n, NgbModalModule, NgbModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule, TranslocoService, TranslocoTestingModule } from '@ngneat/transloco';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';

// modules
import { AppRoutingModule } from './app-routing.module';
import { AppAuthGuard } from './app.authguard';
// components
import { AppComponent } from './app.component';
import { init } from './app.init';
import { NavbarComponent } from '@core/navbar/navbar.component';
import { SecurityService } from '@core/services/security.service';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { BreadcrumbComponent } from '@core/breadcrumb/breadcrumb.component';
import { HeaderComponent } from '@core/header/header.component';
import { LanguageSwitchComponent } from '@core/header/language-switch/language-switch.component';
import { EntitlementsModalComponent } from '@core/header/entitlements-modal/entitlements-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClipboardModule } from 'ngx-clipboard';
import { EntitlementDirective } from '@core/directives/entitlement.directive';
import { TrimDirective } from '@core/directives/trim.directive';
import { UploadDurationComponent } from '@shared/components/upload-duration/upload-duration.component';
import { FcscSeriesOperationComponent } from '@fcsc/fcsc-admin-section/fcsc-series-configuration/components/fcsc-series-operation/fcsc-series-operation.component';
import { FcscComponent } from '@fcsc/fcsc.component';
import { ForbiddenPageComponent } from '@core/forbidden-page/forbidden-page.component';
import { FcscUploadtoolComponent } from '@fcsc/fcsc-uploadtool/fcsc-uploadtool.component';
import { FcscUploadVorabAlertComponent } from '@fcsc/dialogs/fcsc-upload-vorab-alert/fcsc-upload-vorab-alert.component';
import { FcscExperttoolComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-experttool.component';
import { FcscAdminSectionComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-section.component';
import { FcscAdminNotificationManagementComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-notification-management/fcsc-admin-notification-management.component';
import { NotificationDetailComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-notification-management/components/notification-detail/notification-detail.component';
import { NotificationTableComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-notification-management/components/notification-table/notification-table.component';
import { FcscAdminFsfLogsComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-fsf-logs/fcsc-admin-fsf-logs.component';
import { FcscAdminTestautomationComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-testautomation/fcsc-admin-testautomation.component';
import { TestDetailComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-testautomation/components/test-detail/test-detail.component';
import { TestResultComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-testautomation/components/test-result/test-result.component';
import { TestHistoryComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-testautomation/components/test-history/test-history.component';
import { TestStartComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-testautomation/components/test-start/test-start.component';
import { TestCancelComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-testautomation/components/test-cancel/test-cancel.component';
import { FcscUploadVorabComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/fcsc-upload-vorab.component';
import { FcscUploadvorabDeleteConfirmationComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/components/fcsc-upload-vorab-delete-confirmation/fcsc-upload-vorab-delete-confirmation.component';
import { FcscFilterVorabComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/components/fcsc-filter-vorab/fcsc-filter-vorab.component';
import { CustomDatepickerI18nService, I18n } from '@core/services/custom-datepicker-i18n.service';
import { FcscUploadTestComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-test/fcsc-upload-test.component';
import { FcscVp5TestDataComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-test/components/fcsc-vp5-test-data/fcsc-vp5-test-data.component';
import { FcscCreateVp5TestDataComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-test/components/fcsc-vp5-test-data/components/fcsc-create-vp5-test-data/fcsc-create-vp5-test-data.component';
import { FcscSerienImagesComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/fcsc-serien-images.component';
import { FcscEditSeriesModalComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-edit-series-modal/fcsc-edit-series-modal.component';
import { FcscBdpInserieSelectComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-edit-series-modal/fcsc-bdp-inserie-select/fcsc-bdp-inserie-select.component';
import { FcscBdpSeriesQueueComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-bdp-series-queue/fcsc-bdp-series-queue.component';
import { FcscBdsSeriesQueueModalComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-bdp-series-queue/fcsc-bds-series-queue-modal/fcsc-bds-series-queue-modal.component';
import { FcscSerienBdpComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/fcsc-serien-bdp.component';
import { FcscSerienBdpRollbackModalComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/fcsc-serien-bdp-rollback-modal/fcsc-serien-bdp-rollback-modal.component';
import { FcscSerienBdpHistoryDetailsModalComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/fcsc-serien-bdp-history-details-modal/fcsc-serien-bdp-history-details-modal.component';
import { FcscMultiSeriesActivationMainModalComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/mulli-series/fcsc-multi-series-activation-main-modal/fcsc-multi-series-activation-main-modal.component';
import { FcscFilterBdpInserieSelectComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-edit-series-modal/fcsc-bdp-inserie-select/fcsc-filter-bdp-inserie-select/fcsc-filter-bdp-inserie-select.component';
import { FcscRollbackPart1Component } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/fcsc-serien-bdp-rollback-modal/components/fcsc-rollback-part1/fcsc-rollback-part1.component';
import { FcscRollbackPart2Component } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/fcsc-serien-bdp-rollback-modal/components/fcsc-rollback-part2/fcsc-rollback-part2.component';
import { FcscRollbackPart3Component } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/fcsc-serien-bdp-rollback-modal/components/fcsc-rollback-part3/fcsc-rollback-part3.component';
import { FcscFilterSerienBdpComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/fcsc-filter-serien-bdp/fcsc-filter-serien-bdp.component';
import { FcscRollbackPart4Component } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/components/fcsc-serien-bdp-rollback-modal/components/fcsc-rollback-part4/fcsc-rollback-part4.component';
import { FcscSerienUploadListComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-upload-list/fcsc-serien-upload-list.component';
import { FcscEditUploadListComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-upload-list/components/fcsc-edit-upload-list/fcsc-edit-upload-list.component';
import { FcscFilterUploadListComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-upload-list/components/fcsc-filter-upload-list/fcsc-filter-upload-list.component';
import { DatePipe } from '@angular/common';
import { FcscBdpModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-bdp-modal/fcsc-bdp-modal.component';
import { FcscCalculateModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-calculate-modal/fcsc-calculate-modal.component';
import { FcscDeterminedFlashwareInsertComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-calculate-modal/fcsc-determined-flashware-insert/fcsc-determined-flashware-insert.component';
import { FcscFlashwareMultiimportComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-calculate-modal/fcsc-flashware-multiimport/fcsc-flashware-multiimport.component';
import { FcscCalculateComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-calculate/fcsc-calculate.component';
import { FcscFinlogDataReaderComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-finlog-data-reader/fcsc-finlog-data-reader.component';
import { FcscFinlogComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-finlog/fcsc-finlog.component';
import { FcscFlashwareComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-flashware/fcsc-flashware.component';
import { FcscFsfInfoModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-fsf-info-modal/fcsc-fsf-info-modal.component';
import { FcscFwControlUnitsModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-fw-control-units-modal/fcsc-fw-control-units-modal.component';
import { FcscFwEditModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-fw-edit-modal/fcsc-fw-edit-modal.component';
import { FcscFwVedocDetailsComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-fw-vedoc-details/fcsc-fw-vedoc-details.component';
import { FcscFwVehicleCodesFilterComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-fw-vehicle-codes-modal/fcsc-fw-vehicle-codes-filter/fcsc-fw-vehicle-codes-filter.component';
import { FcscFwVehicleCodesModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-fw-vehicle-codes-modal/fcsc-fw-vehicle-codes-modal.component';
import { FcscLogFilesModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-log-files-modal/fcsc-log-files-modal.component';
import { FcscResultDependenciesComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-result-dependencies/fcsc-result-dependencies.component';
import { FcscResultFinlogComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-result-finlog/fcsc-result-finlog.component';
import { FcscResultLogDownloadModalComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/components/fcsc-result-log-download-modal/fcsc-result-log-download-modal.component';
import { FcscFwErmittlungComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/fcsc-fw-ermittlung.component';
import { ValidationService } from '@core/services/validation.service';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FcscFwLogHistoryComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-log-history/fcsc-fw-log-history.component';
import { FcscFilterLogHistoryComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-log-history/components/fcsc-filter-log-history/fcsc-filter-log-history.component';
import { FcscSeriesConfigurationComponent } from '@fcsc/fcsc-admin-section/fcsc-series-configuration/fcsc-series-configuration.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FcscAdminCodeListComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-code-list/fcsc-admin-code-list.component';
import { FcscAdminVp5VersionsComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-vp5-versions/fcsc-admin-vp5-versions.component';
import { ConfirmatonModalComponent } from '@shared/components/confirmaton-modal/confirmaton-modal.component';
import { FcscAdminFsfManipulatorEntitlementComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-fsf-logs/components/fcsc-admin-fsf-manipulator-entitlement/fcsc-admin-fsf-manipulator-entitlement.component';
import { FcscUploadVorabFolderComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/components/fcsc-upload-vorab-folder/fcsc-upload-vorab-folder.component';
import { FcscSdpDetailsComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/components/fcsc-sdp-details/fcsc-sdp-details.component';
import { LoadingBarComponent } from '@shared/components/loading-bar/loading-bar.component';
import { NotFoundPageComponent } from '@core/not-found-page/not-found-page.component';
import { InlineEditingCellComponent } from '@shared/components/inline-editing-cell/inline-editing-cell.component';
import { LoadingSpinnerComponent } from '@shared/components/loading-spinner/loading-spinner.component';
import { VersionComponent } from '@core/version/version.component';
import { ApplicationComponent } from '@core/application/application.component';
import { ChipComponent } from './shared/components/chip/chip.component';
import { FcscSeriesConfigDeleteConfirmationComponent } from '@fcsc/fcsc-admin-section/fcsc-series-configuration/components/fcsc-series-config-delete-confirmation/fcsc-series-config-delete-confirmation.component';
import { FcscGlobalDataComponent } from '@fcsc/fcsc-global-data/fcsc-global-data.component';
import { FcscTCFGDionameBLComponent } from '@fcsc/fcsc-global-data/fcsc-tcfgdioname-bl/fcsc-tcfgdioname-bl.component';


@NgModule({
  declarations: [
    // components
    AppComponent,
    NavbarComponent,
    BreadcrumbComponent,
    HeaderComponent,
    LanguageSwitchComponent,
    EntitlementsModalComponent,
    EntitlementDirective,
    TrimDirective,
    UploadDurationComponent,
    FcscSeriesOperationComponent,
    FcscComponent,
    ForbiddenPageComponent,
    FcscUploadtoolComponent,
    FcscUploadVorabAlertComponent,
    FcscExperttoolComponent,
    FcscAdminSectionComponent,
    FcscAdminNotificationManagementComponent,
    NotificationDetailComponent,
    NotificationTableComponent,
    FcscAdminFsfLogsComponent,
    FcscAdminTestautomationComponent,
    TestDetailComponent,
    TestResultComponent,
    TestHistoryComponent,
    TestStartComponent,
    TestCancelComponent,
    FcscUploadVorabComponent,
    FcscUploadvorabDeleteConfirmationComponent,
    FcscFilterVorabComponent,
    FcscUploadTestComponent,
    FcscVp5TestDataComponent,
    FcscCreateVp5TestDataComponent,
    FcscSerienImagesComponent,
    FcscEditSeriesModalComponent,
    FcscBdpInserieSelectComponent,
    FcscBdpSeriesQueueComponent,
    FcscBdsSeriesQueueModalComponent,
    FcscSerienBdpComponent,
    FcscSerienBdpRollbackModalComponent,
    FcscSerienBdpHistoryDetailsModalComponent,
    FcscMultiSeriesActivationMainModalComponent,
    FcscFilterBdpInserieSelectComponent,
    FcscRollbackPart1Component,
    FcscRollbackPart2Component,
    FcscRollbackPart3Component,
    FcscRollbackPart4Component,
    FcscFilterSerienBdpComponent,
    FcscSerienUploadListComponent,
    FcscEditUploadListComponent,
    FcscFilterUploadListComponent,
    FcscFwErmittlungComponent,
    FcscFwVedocDetailsComponent,
    FcscFwEditModalComponent,
    FcscFwControlUnitsModalComponent,
    FcscFlashwareComponent,
    FcscBdpModalComponent,
    FcscFinlogComponent,
    FcscCalculateComponent,
    FcscCalculateModalComponent,
    FcscResultDependenciesComponent,
    FcscResultLogDownloadModalComponent,
    FcscResultFinlogComponent,
    FcscFinlogDataReaderComponent,
    FcscDeterminedFlashwareInsertComponent,
    FcscLogFilesModalComponent,
    FcscFwVehicleCodesModalComponent,
    FcscFwVehicleCodesFilterComponent,
    FcscFsfInfoModalComponent,
    FcscFlashwareMultiimportComponent,
    FcscFwLogHistoryComponent,
    FcscFilterLogHistoryComponent,
    FcscSeriesConfigurationComponent,
    FcscAdminCodeListComponent,
    FcscAdminVp5VersionsComponent,
    ConfirmatonModalComponent,
    FcscAdminFsfManipulatorEntitlementComponent,
    FcscUploadVorabFolderComponent,
    FcscSdpDetailsComponent,
    LoadingBarComponent,
    NotFoundPageComponent,
    InlineEditingCellComponent,
    LoadingSpinnerComponent,
    VersionComponent,
    ApplicationComponent,
    ChipComponent,
    FcscSeriesConfigDeleteConfirmationComponent,
    FcscGlobalDataComponent,
    FcscTCFGDionameBLComponent
  ],
  imports: [
    // modules (angular)
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    // modules (third-party)
    NgbModule,
    AngularSvgIconModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      timeOut: 6000,
    }),
    // modules
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    NgSelectModule,
    FormsModule,
    NgxDatatableModule,
    ClipboardModule,
    RouterModule,
    NgbTooltip,
    TranslocoModule,
    FileUploadModule,
    NgxFileDropModule,
    NgbModalModule,
    TranslocoTestingModule
  ],
  providers: [
    AppAuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [SecurityService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (
        router: Router,
        errorHandlerService: ErrorHandlerService,
        translocoService: TranslocoService,
        appService: AppService
      ) => {
        return new AuthInterceptor(
          router,
          errorHandlerService,
          translocoService,
          appService
        );
      },
      multi: true,
      deps: [Router, ErrorHandlerService, TranslocoService, AppService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
    DatePipe,
    ValidationService,
    NgbActiveModal
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
  }
}
