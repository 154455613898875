<div class="outer-container">
  <div class="row">
    <div class="filter">
      <form [formGroup]="form">
        <div>
          <div class="row row-cols-auto">
            <div class="col-2">
              <div class="form-group">
                <label for="seriesFilter">Series</label>
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control search"
                  formControlName="seriesFilter"
                  id="seriesFilter"
                  placeholder="{{ 'components.footer.all' | transloco }}"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="vinFilter">FIN</label>
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control search"
                  formControlName="vinFilter"
                  id="vinFilter"
                  placeholder="{{ 'components.footer.all' | transloco }}"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="usernameFilter">{{ 'modules.data-management.table-header.user' | transloco }}</label>
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control search"
                  formControlName="usernameFilter"
                  id="usernameFilter"
                  placeholder="{{ 'components.footer.all' | transloco }}"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="createdFromFilter">{{
                  "modules.expert-tool.log-history.filter.from-date" | transloco
                }}</label>

                <div class="input-group">
                  <input
                    #searchFrom
                    class="form-control dp"
                    placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                    id="createdFromFilter"
                    name="dp"
                    formControlName="createdFromFilter"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    readonly
                    (click)="removeSearchFromFromFocus()"
                  />
                  <div class="input-group-append">
                    <i class="dp" (click)="d.toggle()" class="bi bi-calendar3"></i>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-2">
              <div class="form-group">
                <label for="createdToFilter">{{
                  "modules.expert-tool.log-history.filter.to-date" | transloco
                }}</label>

                <div class="input-group">
                  <input
                    #searchTo
                    class="form-control dp"
                    placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                    id="createdToFilter"
                    name="dp"
                    formControlName="createdToFilter"
                    ngbDatepicker
                    #dto="ngbDatepicker"
                    readonly
                    (click)="removeSearchToFromFocus()"
                  />
                  <div class="input-group-append">
                    <i class="dp" (click)="dto.toggle()" class="bi bi-calendar3"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="button-area-search">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="filterFSFLogsByValues()"
                >
                  {{ "modules.data-management.filter.btn-search" | transloco }}
                </button>

                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="resetFilter()"
                >
                  {{ "modules.data-management.filter.btn-reset" | transloco }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
<br/>
  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
        #dataTable
        class="material datatable-scrollable"
        [columnMode]="'flex'"
        [headerHeight]="56"
        rowHeight="auto"
        [scrollbarH]="false"
        [scrollbarV]="false"
        [rows]="logs"
        [footerHeight]="56"
        (page)="setPage($event)"
        (sort)="sortCallback($event)"
        [sorts]="sortConfig"
        [messages]="{ emptyMessage: emptyMessage | transloco }"
      >
          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="Serie"
            [flexGrow]="2"
            [resizeable]="false"
            prop="series"
            [sortable]="true"
          >
          </ngx-datatable-column>
        <ngx-datatable-column
            [cellClass]="'text-right'"
            name="FIN"
            [flexGrow]="2"
            [resizeable]="false"
            prop="vin"
            [sortable]="true"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
              [cellClass]="'text-right'"
              name="{{ 'modules.data-management.table-header.user' | transloco }}"
              [flexGrow]="2"
              [resizeable]="false"
              prop="username"
              [sortable]="true"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
                [cellClass]="'text-right'"
                name="{{ 'modules.admin-section.creation-date' | transloco }}"
                [flexGrow]="2"
                [resizeable]="false"
                prop="createdAt"
                [sortable]="true"
              >
              <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                >
                  <span *ngIf="value">{{ value | date : "dd.MM.yyyy HH:mm:ss":'UTC' }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                  [cellClass]="'text-right'"
                  name="{{ 'modules.admin-section.ecus' | transloco }}"
                  [flexGrow]="4"
                  [resizeable]="false"
                  prop="ecus"
                  [sortable]="true"
                >
                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ createECUMap(value) }}</span>
                  </ng-template>
                </ngx-datatable-column>
              <ng-container>
              <ngx-datatable-column
                  [cellClass]="'text-right'"
                  name="{{ 'global.actions' | transloco }}"
                  [sortable]="false"
                  [flexGrow]="3"
                  [resizeable]="false"
                >
                <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                  <div class="action-column">
                    <span
                      title="{{
                        'modules.expert-tool.result.log.btn-result-file-download-log' | transloco
                      }}"
                      (click)="downloadDecryptedFile(row)"
                    >
                      <i class="bi bi-download"></i>
                    </span>
                    <span
                      title="{{
                        'components.header.entitlements-view' | transloco
                      }}"
                      (click)="displayEntitlements(row)"
                    >
                      <i class="bi bi-person-fill"></i>
                    </span>
                  </div>
                </ng-template>
                </ngx-datatable-column>
              </ng-container>
              <ngx-datatable-footer *ngIf="true">
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-offset="offset"
                  let-curPage="curPage"
                >
                  <div class="col-8">
                    <datatable-pager
                      [pagerLeftArrowIcon]="'datatable-icon-left'"
                      [pagerRightArrowIcon]="'datatable-icon-right'"
                      [pagerPreviousIcon]="'datatable-icon-prev'"
                      [pagerNextIcon]="'datatable-icon-skip'"
                      [count]="response?.totalElements || 0"
                      [page]="page.pageNumber"
                      [size]="page.size"
                      (change)="setPage($event)"
                    >
                    </datatable-pager>
                  </div>

                  <div class="col-4 page-result-section server-side">
                    <span class="float-end">
                      <span class="result-text">{{
                        "components.footer.result-per-page" | transloco
                      }}</span>
                      <span
                        class="result-value"
                        [ngClass]="{ active: page.size === 10 }"
                        (click)="setPageSize(10)"
                        >10</span
                      >|
                      <span
                        class="result-value margin-override"
                        [ngClass]="{ active: page.size === 30 }"
                        (click)="setPageSize(30)"
                        >30</span
                      >|
                      <span
                        class="result-value"
                        [ngClass]="{ active: page.size === 100 }"
                        (click)="setPageSize(100)"
                        >100</span
                      >
                      <span>
                        {{ "components.footer.files" | transloco }}:
                        {{
                          response && (response?.totalElements || 0) > 0
                            ? (response?.number || 0) * page.size + 1
                            : 0
                        }}
                        -
                        {{
                          isNaN(
                            (response?.number || 0) * page.size +
                              (response?.numberOfElements || 0)
                          )
                            ? 0
                            : (response?.number || 0) * page.size +
                              (response?.numberOfElements || 0)
                        }}
                      </span>
                      <span>
                        {{ "components.footer.of" | transloco }}
                        {{
                          response?.totalElements ? response?.totalElements : 0
                        }}</span
                      >
                    </span>
                  </div>
                </ng-template>
              </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
